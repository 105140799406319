import React, {useEffect, useState} from "react";

type Props = {
    children: React.ReactElement;
    type: 'mobile' | 'desktop'
}

export const DeviceComponent = ({children, type}: Props) => {

    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if ((width > 600 && type === 'mobile') || (width < 600 && type === 'desktop')) {
        return <></>
    } else {
        return children
    }

}
