import {SlideUpTransition} from "../extended/DialogTransitions";
import {Box, Dialog, DialogContent, DialogTitle, Divider, IconButton, Typography, useMediaQuery} from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CloseIcon from "@mui/icons-material/Close";
import React, {useEffect} from "react";
import {useTheme} from "@mui/material/styles";

type Props = {
    open: boolean
    handleClose: () => void
    title?: string
    children: React.ReactNode;
    size?: 'slim' | 'full'
}
export const DialogPage = ({open, handleClose, children, title, size = 'slim'}: Props) => {

    const theme = useTheme();

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const width = size === 'full' ? '900px' : '600px';

    useEffect(() => {
        const metaThemeColor = document.querySelector('meta[name="theme-color"]');
        if (metaThemeColor) {
            if (open) {
                metaThemeColor.setAttribute('content', theme.palette.primary.main)
            } else {
                metaThemeColor.setAttribute('content', '#ffffff')
            }
        }

    }, [open]);

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            TransitionComponent={SlideUpTransition}
            onClose={handleClose}
            sx={{'& .MuiPaper-root': {width: {sx: '100%'}, maxWidth: {lg: '900px'}}}}
        >
            <DialogTitle sx={{backgroundColor: theme.palette.primary.main, color: 'white'}}>
            <Box >
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        [theme.breakpoints.up('md')]: {
                            display: 'none'
                        },
                        position: 'absolute',
                        color: 'white',
                        top: 5,
                        left: 8,
                    }}
                >
                    <ArrowBackIosNewIcon/>
                </IconButton>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        [theme.breakpoints.down('md')]: {
                            display: 'none'
                        },
                        position: 'absolute',
                        top: 5,
                        right: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
                <Typography sx={{marginLeft: {xs: '40px', sm: '40px', md: 0}, textTransform: 'capitalize', fontSize: '1.2rem', fontWeight: 'bold'}}  variant={'body1'}>{title}</Typography>
            </Box>
            </DialogTitle>
            <Divider/>
            <DialogContent sx={{padding: {xs: '20px', sm:size === 'full' ? '40px' : '20px'}, minWidth: {sm: '400px'}}}>
                {children}
            </DialogContent>
        </Dialog>
    )
}
