import {Box, Button, Divider, List, ListItem, Stack, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {CalendarEvent} from "../../../store/feature/calendar";
import {useTranslation} from "react-i18next";
import {EVENT_TYPES} from "../../../themes/eventTypes";
import {useSearchParams} from "react-router-dom";
import {useTheme} from "@mui/material/styles";
import dayjs, {Dayjs} from "dayjs";
import {EmptyBox} from "../EmptyBox";
import {getDate, getYearMonth, getYearMonthDayJsDate} from "../../../utils/misc";
import {AbsenceCancellationButton} from "./AbsenceCancellationButton";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

type StudentDateEvents = {
    date: string;
    events: CalendarEvent[];
}

type Props = {
    calendarEvents: CalendarEvent[]
    selectedDate: Dayjs | string
    setSelectedDate: (day: Dayjs | string) => void
}

export const StudentEvents = ({ calendarEvents, selectedDate, setSelectedDate }: Props) => {

    const { t } = useTranslation();
    const [events,setEvents] = React.useState<StudentDateEvents[]>([]);
    const [filtered,setFiltered] = React.useState<boolean>(false);
    const [searchParams, setSearchParams] = useSearchParams();

    const theme = useTheme();

    useEffect(() => {
            let result: StudentDateEvents[] = [];
            let lastDate = ''
            let dateEvents:StudentDateEvents = {
                date: '',
                events: []
            }
        calendarEvents
                .filter((e) => !searchParams.get('date') || e.date === searchParams.get('date'))
                .forEach((e) => {
                    if (e.date !== lastDate) {
                        dateEvents = {
                            date: e.date,
                            events: []
                        }
                        result.push(dateEvents)
                        lastDate = e.date
                    }
                    dateEvents.events.push(e)
                })
            setEvents(result);
            setFiltered(!!searchParams.get('date'))
    }, [calendarEvents, searchParams])

    return (
        <Box>
            <Stack sx={{flexDirection: {xs: 'column', sm: 'row'}}}  justifyContent={'space-between'} alignItems={'center'}>
                <Typography sx={{height: 50, color: '#434343', margin: '5px 0 0 15px', textTransform: 'capitalize'}} variant={'h2'}>{ ( searchParams.get('date') ? getDate(searchParams.get('date'))?.format('D MMMM') : getYearMonthDayJsDate(selectedDate).format('MMMM') ) }</Typography>
                {filtered && (<Button sx={{marginBottom: '20px'}} startIcon={<CalendarMonthIcon/>} onClick={() => {
                    searchParams.delete('date');
                    setSearchParams(searchParams);
                    setSelectedDate(getYearMonth(selectedDate))
                }} >
                    {t('widget.studentEvents.clear')}
                </Button>)
                }
            </Stack>

            <Box sx={{
                [theme.breakpoints.up('lg')]: {
                    height: 'calc(100vh - 280px)',
                    overflow: 'auto'
                }
            }}>

                {events.length === 0 && <EmptyBox variant={'events'} padding={'0'} title={filtered ? t('events.day.empty') : t('events.month.empty')}/>}
                <List>

                    {events.map((event: StudentDateEvents, index) => {
                        const date = dayjs(event.date).format('D ddd').split(' ');
                        const dayNumber = date[0];
                        const dayName = date[1];
                        return (
                            <Box key={index}>
                        <ListItem id={`event-${event.date}`} sx={{margin: '0', padding: '0'}} >
                            <Box sx={{padding:'0 5px', width: '100%'}}>
                                <Stack direction={'row'}>
                                    <Box sx={{width: {xs: '60px', md: '120px'},fontSize: '1.6rem', fontWeight: 'bold', color: '#494949', textAlign: 'center'}}>
                                        <span style={{fontSize: '1.6rem', fontFamily: 'system-ui', fontWeight: '900', color: '#494949', display: 'block'}}>{dayNumber}</span>
                                        <span style={{fontSize: '1rem',  color: 'black', textTransform: 'capitalize'}}>{dayName}</span>
                                    </Box>
                                    <List sx={{p: 0, m: 0, width: '100%'}}>
                                        {event.events.map((dayEvent: CalendarEvent, index) => (
                                            <Box key={index}>
                                                <ListItem sx={{borderLeft: `3px solid ${EVENT_TYPES[dayEvent.eventType].lightColor}`, margin: '0', boxShadow: 'rgba(0, 0, 0, 0.1) 0 0 4px', marginBottom: '15px',  padding: '0', borderRadius: '7px', backgroundColor: `${EVENT_TYPES[dayEvent.eventType].extraLight}`}} >
                                                    <Box sx={{padding:2, width: '100%'}}>
                                                        <Stack direction={"row"} sx={{width: '100%'}} justifyContent={'space-between'}>
                                                            {dayEvent.start && dayEvent.end &&
                                                                (<Typography variant={'h6'} sx={{fontSize: '0.9rem', fontWeight: 'bold', marginRight: '30px', marginBottom: '0px'}}>{dayEvent.start} - {dayEvent.end}</Typography>)
                                                            }
                                                            <Typography variant={'h5'} color={'#7c7e82'} sx={{fontSize: '0.75rem'}} >{t(EVENT_TYPES[dayEvent.eventType].name)}</Typography>
                                                        </Stack>

                                                        {dayEvent.info && (<Typography variant={'body2'} sx={{fontSize: '1.1rem', paddingTop: '10px'}}>{dayEvent.info}</Typography>)}

                                                        {(dayEvent.eventType === 'extra-class' && dayEvent.cancelled) && (
                                                            <Stack alignItems={'end'}>
                                                                <Typography variant={'h4'} color={'#d2013f'}> {t('Cancelled')} </Typography>
                                                            </Stack>

                                                        )}

                                                        {dayEvent.eventType === 'absence' && (dayjs(dayEvent.date).isToday() || dayjs().isBefore(dayjs(dayEvent.date), 'day')) && (
                                                            <AbsenceCancellationButton date={dayEvent.date} selectedDate={selectedDate} />
                                                        )}
                                                    </Box>
                                                </ListItem>
                                            </Box>
                                            ))}
                                    </List>
                                </Stack>

                            </Box>
                        </ListItem>
                        {index !== events.length - 1 &&<Divider  sx={{ margin:'0 0 15px 0'}} />}
                            </Box>
                    )})}
                </List>
            </Box>
        </Box>
    )
}
