import React, {useEffect} from "react";
import {useFetchChatMessages} from "../../hooks/fetch/useFetchChatMessages";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {
    fetchLatestFacilityChatMessages,
    fetchMoreFacilityChatMessages,
} from "../../store/feature/chat";
import {ChatWindow} from "../../ui-component/widget/chat/ChatWindow";
import {Box} from "@mui/material";
import {Loader} from "../../ui-component/Loader";
import {fetchStudentsDetails, selectStudentsDetails} from "../../store/feature/user";
import {MessageInput} from "../../ui-component/widget/chat/MessageInput";
import {DeviceComponent} from "../../ui-component/DeviceComponent";

export const ChatPage = () => {
    const dispatch = useAppDispatch();
    const studentData = useAppSelector((state) => selectStudentsDetails(state));
    const {activeStudentId, id} = useAppSelector((state) => state.configuration);

    const messages = useFetchChatMessages();
    useEffect(() => {
        if (studentData && activeStudentId) {
            studentData.filter((s) => s.id === activeStudentId).forEach((s) => {
                if(!s.chatRead) {
                    dispatch(fetchLatestFacilityChatMessages({studentId: activeStudentId, userId: id})).then(() => dispatch(fetchStudentsDetails()))
                }
            })
        }
    }, [studentData, activeStudentId]);


    const loadMore = (page: number) => {
        return dispatch(fetchMoreFacilityChatMessages({studentId: activeStudentId, userId: id, page: page, size: messages.pageable.pageSize}))
    }
    // Message input for mobile is in footer
    return (
        <Box sx={{maxWidth: '900px', width: '100%', margin: 'auto'}}>
            {messages && (
                <ChatWindow
                    pageableItems={messages}
                    loadMore={loadMore}
                />
            )}
            {!messages && <Loader/>}
            <DeviceComponent type={"desktop"}>
                <MessageInput position={"fixed"} sx={{display: {sx: 'none', sm: 'flex'}}} />
            </DeviceComponent>

        </Box>
    )
}
