import {Box, Divider, Stack, Typography} from "@mui/material";
import {Form} from "../../../../library/Form";
import React, {useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import {addStudentMealCancellation, fetchUserMeals, Meal} from "../../../../store/feature/user";
import {CheckBoxGroupField} from "../../../form/CheckBoxGroupField";
import {Dayjs} from "dayjs";
import {CalendarEvent, selectCalendarEvents} from "../../../../store/feature/calendar";
import {StaticDatePickerInputField} from "../../../form/StaticDatePickerInputField";
import {SubmitButton} from "./SubmitButton";
import {getYearMonthDayJsDate} from "../../../../utils/misc";

type Props = {
    goToMainMenu: () => void
    goToSuccess: () => void
    studentId: number
    calendarEvents: CalendarEvent[]
    language: string
    selectedDate: Dayjs | string
    setSelectedDate: (day: Dayjs | string) => void
}
export const CancelMealForm = ({selectedDate, setSelectedDate, goToSuccess, studentId, language, calendarEvents}: Props) => {
    const form = useForm();
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [meals, setMeals] = React.useState<Meal[]>([]);
    const userEvents = useAppSelector((state) => selectCalendarEvents(state));
    const [mealsCancelledDays, setMealsCancelledDays] = useState<string[]>([]);
    const selectedDays = form.watch('days') ;
    const selectedMeals = form.watch('meals') ?? meals.length ;


    const cancelMeals = (value: any) => {
        return addStudentMealCancellation({days: value.days, mealIds: value.meals, studentId});
    }

    const updateDays = (day: Dayjs) => {
        setSelectedDate(day)
    }

    useEffect(() => {
        const events = calendarEvents.length > 0 ? calendarEvents : userEvents;
        if (Array.isArray(events)) {
            const days = events.filter((e) => e.eventType === 'cancelled-meals' || e.eventType === 'absence' || e.eventType === 'holiday').map((e) => e.date);
            setMealsCancelledDays(days);
        }
    }, [userEvents, calendarEvents])

    useEffect(() => {
        dispatch(fetchUserMeals({studentId, language})).then(meals => {
            setMeals(meals.payload as Meal[])
        })
    },[studentId, language])

    const showButton = Array.isArray(selectedDays) && Array.isArray(selectedMeals) && selectedDays.length > 0 && selectedMeals.length > 0;
    const [processing, setProcessing] = useState(false)

    return (
        <div>
            <Stack spacing={2}>
                <Typography variant={'body2'}>{t('widget.planChanges.meals.help')}</Typography>
                <Box sx={{mb: 3, mt: 3}}>
                    <Typography variant={'body2'}>{t('widget.planChanges.main.help')}</Typography>
                </Box>
                <Form form={form} id={'meal-cancel-form'} submitAction={cancelMeals} onBeforeSubmit={() => { setProcessing(true); return true }}  onSubmitSuccess={() => goToSuccess()}>
                    <StaticDatePickerInputField defaultValue={getYearMonthDayJsDate(selectedDate)} onMonthChange={updateDays} name={'days'} form={form} infoDays={mealsCancelledDays}/>
                    <Divider sx={{margin: '15px 0'}}/>
                    <Typography variant={'h4'}>{t('widget.planChanges.meals.name')}</Typography>
                    <Typography variant={'body2'}>{t('widget.planChanges.meals.action.help')}</Typography>

                    {meals.length > 0 && <CheckBoxGroupField name={"meals"} label={"MEAL"} checkedByDefault={true} inverseValues={true} options={meals.map(m => ({label: m.name, value: m.mealId}))} form={form}/>}

                    <Stack alignItems={"end"}>
                        <SubmitButton waitingForAction={processing} type={"submit"} variant={'contained'} disabled={!showButton}>{t('widget.planChanges.form.submit.button')}</SubmitButton>
                    </Stack>
                </Form>

            </Stack>
        </div>
    )
}
