import {buildSlice, createGenericAsyncThunk} from "../../../generic";
import {createSelector} from "@reduxjs/toolkit";
import {AppState} from "../../index";
import {API_URL} from "../../../config";

export type CalendarEvent = {
    date: string;
    cancelled: boolean;
    eventType: 'absence' | 'holiday' | 'day-care' | 'custom' | 'extra-class' | 'cancelled-meals';
    info: string;
    start: string;
    end: string;
};

export type CalendarData = {
    studentId: number;
    yearMonth: string;
    language: string;
}

export const fetchCalendarEvents = createGenericAsyncThunk<CalendarEvent[], CalendarData>(
  'calendar/fetchCalendarEvents',
  async ({ studentId, yearMonth, language }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(
      `${API_URL}/api/public/v1/students/${studentId}/calendar-events/${yearMonth}`,
        {
            headers: {
                'Accept-Language': language
            }
        }

    );
    const data: CalendarEvent[] = await response.json();

    if (response.status !== 200) {
      return rejectWithValue({statusCode: response.status, response: data});
    }
    return data;
  }
);

const calendarDatesSlice = buildSlice<CalendarEvent[], CalendarData>(
  'calendarDatesSlice', fetchCalendarEvents.fulfilled
);
export const calendarEventsReducer = calendarDatesSlice.reducer;

export const selectCalendarEvents = createSelector([(state: AppState) => state.calendarEvents], (events) => events.data);

