import {Chip} from "@mui/material";
import React from "react";
import {useTheme} from "@mui/material/styles";

type Props = {
    icon: React.ReactElement,
    anchorRef: any

}
export const SettingsChip = ({icon, anchorRef}: Props) => {

    const theme = useTheme();

    return (
        <Chip
            sx={{
                height: '48px',
                alignItems: 'center',
                borderRadius: '27px',
                transition: 'all .2s ease-in-out',
                borderColor: theme.palette.primary.light,
                backgroundColor: theme.palette.primary.light,
                '&[aria-controls="menu-list-grow"], &:hover': {
                    borderColor: theme.palette.primary.main,
                    background: `${theme.palette.primary.main}!important`,
                    color: theme.palette.primary.light,
                    '& svg': {
                        stroke: theme.palette.primary.light
                    }
                },
                '& .MuiChip-label': {
                    lineHeight: 0,
                    padding: 0
                }
            }}
            icon={ icon }
            variant="outlined"
            ref={anchorRef}
            aria-haspopup="true"
            color="primary"
        />
    )
}
