export const SET_APP_VISIBLE = '@notification/SET_APP_VISIBLE';

export type MiscState = {
    appIsActive: boolean,
}

export type SetAppIsActiveAction = {
    type:  typeof SET_APP_VISIBLE;
    value: boolean
}

export const initialState = {
    appIsActive: true,
};


// ==============================|| MISC REDUCER ||============================== //

export const miscReducer = (state = initialState, action: SetAppIsActiveAction ): MiscState => {

    switch (action.type) {
    case SET_APP_VISIBLE:
      return {
        ...state,
          appIsActive: action.value
      };

        default:
      return state;
  }
};
