import {Box, Stack, Typography} from "@mui/material";
import {Form} from "../../../../library/Form";
import {useForm} from "react-hook-form";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {StaticDatePickerInputField} from "../../../form/StaticDatePickerInputField";
import {useAppSelector} from "../../../../hooks";
import {CalendarEvent, selectCalendarEvents} from "../../../../store/feature/calendar";
import {addStudentAbsence} from "../../../../store/feature/user";
import {Dayjs} from "dayjs";
import {SubmitButton} from "./SubmitButton";
import {InputField} from "../../../form/InputField";
import {getYearMonthDayJsDate} from "../../../../utils/misc";

type Props = {
    goToMainMenu: () => void
    goToSuccess: () => void
    studentId: number
    language: string
    calendarEvents: CalendarEvent[]
    selectedDate: Dayjs | string
    setSelectedDate: (day: Dayjs | string) => void
}
export const AbsenceForm = ({selectedDate, setSelectedDate, goToSuccess, studentId, language, calendarEvents}: Props) => {

    const form = useForm();
    const {t} = useTranslation();
    const userEvents = useAppSelector((state) => selectCalendarEvents(state));
    const [absenceDays, setAbsenceDays] = useState<string[]>([]);

    const addAbsence = (value: any) => addStudentAbsence({days: value.days, studentId, reason: value.reason});
    const selectedDays = form.watch('days');
    const reason = form.watch('reason');

    const updateDays = (day: Dayjs) => {
       setSelectedDate(day)
    }

    useEffect(() => {
        const events = calendarEvents.length > 0 ? calendarEvents : userEvents;
        if (Array.isArray(events)) {
            const days = events.filter((e) => e.eventType === 'absence' || e.eventType === 'holiday').map((e) => e.date);
            setAbsenceDays(days);
        }
    }, [userEvents, calendarEvents])

    const [processing, setProcessing] = useState(false)

     return (
         <div>
             <Stack spacing={2}>
                 <Typography variant={'body2'}>{t('widget.planChanges.absence.help.1')}</Typography>
                 <Typography variant={'body2'}>{t('widget.planChanges.absence.help.2')}</Typography>


                 <Form form={form} id={'absence-form'} submitAction={addAbsence} onBeforeSubmit={() => { setProcessing(true); return true }} onSubmitSuccess={() => goToSuccess()}>

                     <InputField name={"reason"} label={t('widget.planChanges.reason')} placeHolder={t('widget.planChanges.reason.placeholder')} rows={2} form={form} required sx={{marginBottom: '20px'}}/>

                     <Typography sx={{fontSize: '1.1rem', fontWeight: 'bold'}} variant={'h5'}>{t('widget.planChanges.select.days')}:</Typography>
                     <StaticDatePickerInputField defaultValue={getYearMonthDayJsDate(selectedDate)} onMonthChange={updateDays} name={'days'} form={form} infoDays={absenceDays}/>

                     <Stack alignItems={"end"}>
                         <SubmitButton waitingForAction={processing} type={"submit"} variant={'contained'} disabled={!reason || !Array.isArray(selectedDays) || selectedDays.length === 0}>{t('widget.planChanges.form.submit.button')}</SubmitButton>
                     </Stack>
                 </Form>
             </Stack>
         </div>
     )
 }
