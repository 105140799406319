import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import config from "./config";
import {BrowserRouter} from "react-router-dom";
import {store} from "./store";
import './i18n'
import ErrorBoundary from "./ui-component/common/ErrorBoundary";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
      <Provider store={store}>
          <ErrorBoundary>
              <BrowserRouter basename={config.basename}>
                  <App />
              </BrowserRouter>
          </ErrorBoundary>
      </Provider>

);

