import { combineReducers } from 'redux';
import {emailPreferenceReducer, studentDetailsReducer, studentsFetchChangeRequestsReducer} from "./feature/user";
import {configurationReducer} from "./configurationReducer";
import {calendarEventsReducer} from "./feature/calendar";
import {userChatMessagesReducer} from "./feature/chat";
import {notificationReducer} from "./notificationReducer";
import {studentEventsReducer, studentGalleriesReducer} from "./feature/event";
import {siteEventsReducer} from "./siteEventsReducer";
import {customizationReducer} from "./customizationReducer";
import {miscReducer} from "./miscReducer";


export const reducer = combineReducers({
    students: studentDetailsReducer,
    studentChangeRequests: studentsFetchChangeRequestsReducer,
    events: studentEventsReducer,
    galleries: studentGalleriesReducer,
    userEmailPreferences: emailPreferenceReducer,
    chatMessages: userChatMessagesReducer,
    calendarEvents: calendarEventsReducer,
    configuration: configurationReducer,
    siteEvents: siteEventsReducer,
    notification: notificationReducer,
    customize: customizationReducer,
    misc: miscReducer
})
