export const SET_TITLE_VISIBLE = '@notification/SET_TITLE_VISIBLE';

export type CustomizationState = {
    showTitle: boolean,
}

export type SetTitleVisibleAction = {
    type:  typeof SET_TITLE_VISIBLE;
    value: boolean
}

export const initialState = {
    showTitle: true,
};


// ==============================|| CUSTOMIZATION REDUCER ||============================== //

export const customizationReducer = (state = initialState, action: SetTitleVisibleAction ): CustomizationState => {

    switch (action.type) {
    case SET_TITLE_VISIBLE:
      return {
        ...state,
          showTitle: action.value
      };

        default:
      return state;
  }
};
