import * as React from 'react';
import {useEffect} from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {Link, useLocation} from "react-router-dom";
import {MainRoutes} from "../../routes/MainRoutes";
import {useAppDispatch} from "../../hooks";
import {SET_SHOW_SUB_MENU} from "../../store/configurationReducer";

function samePageLinkNavigation(
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
) {
    if (
        event.defaultPrevented ||
        event.button !== 0 || // ignore everything but left-click
        event.metaKey ||
        event.ctrlKey ||
        event.altKey ||
        event.shiftKey
    ) {
        return false;
    }
    return true;
}


export const HorizontalNavigation = () => {

    const dispatch = useAppDispatch();

    const [value, setValue] = React.useState(0);
    const [subPages, setSubPages] = React.useState<{path: string, label: string}[]>([]);
    const location = useLocation();
    useEffect(() => {
        const pathArr = location.pathname.split('/');
        if (MainRoutes && pathArr.length >= 1) {
            const parentPagePath = '/' + pathArr[1];
            const page = MainRoutes.children?.filter((p) => p.path === parentPagePath);
            if (page && page.length > 0 && page[0].children && page[0].children.length > 0) {
                // @ts-ignore
                const subPages = page[0].children.filter((p) =>  p.path)
                    .map((p) => {
                        // @ts-ignore
                        const path: string = parentPagePath + '/' +  p.path!;
                        // @ts-ignore
                        const label: string = p.label ? p.label : p.path;
                        return {path, label}
                    });
                setSubPages(subPages);
                dispatch({ type: SET_SHOW_SUB_MENU, value: subPages.length > 0})
                return;
            }
        }
        dispatch({ type: SET_SHOW_SUB_MENU, value: false})
        setSubPages([]);

    }, [location.pathname, MainRoutes]);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        // event.type can be equal to focus with selectionFollowsFocus.
        if (
            event.type !== 'click' ||
            (event.type === 'click' &&
                samePageLinkNavigation(
                    event as React.MouseEvent<HTMLAnchorElement, MouseEvent>,
                ))
        ) {
            setValue(newValue);
        }
    };

    return (
        <>
            {subPages.length === 0 && (<Box sx={{ width: '100%', height: 0 }}></Box>)}
            {subPages.length > 0 && (<Box sx={{ width: '100%', marginTop: '-10px' }}>
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="nav tabs example"
                role="navigation"
                centered
            >
                {subPages.map((p) => <Tab component={Link} label={p.label} to={p.path} key={p.path} />)}
            </Tabs>
        </Box>)}
        </>
    );
}
