import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';

// constant
export const MainCardHeaderSX = {
  '& .MuiCardHeader-action': { mr: 0 }
};

// ==============================|| CUSTOM MAIN CARD ||============================== //

type MainCardProps = {
  border?: boolean
  boxShadow?: boolean,
  children: React.ReactNode;
  content?: boolean,
  contentClass?: string,
  contentSX?: object,
  darkTitle?: boolean,
  secondary?: React.ReactNode,
  shadow?: string,
  sx?: object,
  elevation?: number,
  title?: React.ReactNode
}

const MainCard =
  (
    {
      border = true,
      boxShadow,
      children,
      content = true,
      contentClass = '',
      contentSX = {},
      darkTitle,
      secondary,
      shadow,
            elevation,
      sx = {},
      title,
      ...others
    } : MainCardProps,
  ) => {
    const theme = useTheme();

    return (
      <Card

        {...others}
        elevation={elevation}
        sx={{
          border: border ? '1px solid' : 'none',
          borderColor: theme.palette.primary.dark,
          ':hover': {
            boxShadow: boxShadow ? shadow || '0 2px 14px 0 rgb(32 40 45 / 8%)' : 'inherit'
          },
          ...sx
        }}
      >
        {/* card header and action */}
        {title && <CardHeader sx={MainCardHeaderSX} title={darkTitle ? <Typography variant="h3">{title}</Typography> : title} action={secondary} />}

        {/* content & header divider */}
        {title && <Divider />}

        {/* card content */}
        {content && (
          <CardContent sx={contentSX} className={contentClass}>
            {children}
          </CardContent>
        )}
        {!content && children}
      </Card>
    );
  }

export default MainCard;
