import {useTheme} from "@mui/material/styles";
import {Box, Stack} from "@mui/material";
import React, {useEffect} from "react";


import {useFetchStudentEvents} from "../../hooks/fetch/useFetchStudentEvents";
import {
    fetchStudentsDetails,
    selectStudentsDetails
} from "../../store/feature/user";
import {EmptyBox} from "../../ui-component/widget/EmptyBox";
import {Loader} from "../../ui-component/Loader";
import {
    EventInvoice,
    EventMessage,
    fetchLatestStudentEvents,
    fetchMoreStudentEvents,
    markAsReadStudentEvent, SchoolEvent
} from "../../store/feature/event";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {StudentEventsList} from "../../ui-component/widget/events/StudentEventsList";
import {PageableList} from "../../types";
import {DialogPage} from "../../ui-component/widget/DialogPage";
import {AnnounceCard} from "../../ui-component/widget/events/AnnounceCard";
import {InvoiceCard} from "../../ui-component/widget/events/InvoiceCard";
import {useTranslation} from "react-i18next";

export const NotificationPage = () => {
    const theme = useTheme();
    const {t} = useTranslation();
    const [open, setOpen] = React.useState(false);
    const {activeStudentId, language} = useAppSelector((state) => state.configuration);
    const studentData = useAppSelector((state) => selectStudentsDetails(state));
    const [event, setEvent] = React.useState<SchoolEvent<EventMessage | EventInvoice> | undefined>(undefined);
    const dispatch = useAppDispatch();
    const loadMore = (page: number) => {
        return dispatch(fetchMoreStudentEvents({studentId: activeStudentId, language, page, size: 10}))
    }
    const events = useFetchStudentEvents();

    const handleClose = () => {
        setOpen(false);
        setEvent(undefined);
    };

    useEffect(() => {
        if (studentData && activeStudentId) {
            studentData.filter((s) => s.id === activeStudentId).forEach((s) => {
                if(!s.eventsRead) {
                    dispatch(fetchLatestStudentEvents({studentId: activeStudentId, language}))
                }
            })
        }
    }, [studentData, activeStudentId]);


    const handleOpen = (event: SchoolEvent<EventMessage | EventInvoice>) => {
        setEvent(event);
        setOpen(true);
        if(!event.read) {
            dispatch(markAsReadStudentEvent({eventId: event.id}))
                .then(()  => dispatch(fetchLatestStudentEvents({studentId: activeStudentId, language})))
                .then((resp) => {
                    const newEvents = resp.payload as PageableList<SchoolEvent<EventMessage | EventInvoice>>;
                    if(newEvents.content.filter((e) => e.read === false ).length === 0) {
                        dispatch(fetchStudentsDetails());
                    }
                })
        }
    };

    return (
        <Box sx={{padding: '20px'}}>
            <DialogPage open={open} handleClose={handleClose} size={'full'} title={event?.type}>
                {event?.type === 'announcement' && <AnnounceCard event={event as SchoolEvent<EventMessage>}/>}
                {event?.type === 'invoice' && <InvoiceCard event={event as SchoolEvent<EventInvoice>}/>}

            </DialogPage>

            <Stack spacing={{xs: 1, sm: 2, md: 4 }} sx={{width: '100%'}} justifyContent={"center"} direction={{ xs: 'column', sm: 'row' }} >
                <Box sx={{
                    width: '100%',
                    [theme.breakpoints.up('lg')]: {
                        minWidth: '1200px',
                        padding: 0,
                    },
                    maxWidth: '1200px'
                }}>
                    {!events && <Loader/>}
                    {events && events.content.length === 0 && <EmptyBox variant={'messages'} title={t('notifications.empty')}/>}
                    {events && events.content.length > 0 && (
                        <StudentEventsList events={events} onEventClick={handleOpen} loadMore={loadMore} />
                    )}
                </Box>
            </Stack>
        </Box>
    )
}
