import {Button, ButtonProps} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {useTheme} from "@mui/material/styles";


type Props = ButtonProps & {
    waitingForAction?: boolean
}

export const SubmitButton   = (props: Props)=> {

    const theme = useTheme();

    return (
        <>
             <Button sx={{borderRadius: 50, color: 'white', width:{xs: '100%', sm: '120px'}}} {...props} disabled={props.waitingForAction ? true : props.disabled}>{props.children}</Button>
            {props.waitingForAction && (
                <CircularProgress
                    size={22}
                    sx={{
                        color: theme.palette.success.main,
                        position: 'absolute',
                        left: '50%',
                        marginTop: '8px'
                    }}
                />
            )}
        </>
    )
}
