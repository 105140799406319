import { Box } from '@mui/material';
import React, {CSSProperties} from "react";

type Props = {
    style?: CSSProperties | undefined;
    show: boolean;
    direction: 'up' | 'down'
}
export const ScrollLoader = ({ show, direction}: Props) => {




const marginTop = direction === 'up' && !show ? '-45px' : '0px';
const display = direction === 'down' && !show ? 'none' : 'block';

  return (
      <div style={{transition: 'all .2s ease-out', marginTop, display }}>
        <Box sx={{display: 'flex', textAlign: 'center', justifyContent: 'center', padding: '10px 20px'}}>
          <Box
            component="img"
            sx={{
              width: `20px`,
              opacity: '0.3',
              height: `20px`,
            }}
            src={'/loader.gif'}
            alt="Compass"
          />
        </Box>
      </div>
  )
}
