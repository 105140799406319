// indexedDB.js
import { openDB } from 'idb';

const DB_NAME = 'imageCache';
const STORE_NAME = 'images';
const DB_VERSION = 1; // Increment this if you change the DB schema
//const EXPIRATION_TIME = 300 * 24 * 60 * 60 * 1000; // 300 days in milliseconds

export const initDB = async () => {
  const db = await openDB(DB_NAME, DB_VERSION, {
    upgrade(db) {
      db.createObjectStore(STORE_NAME, {
        keyPath: 'fileName',
        autoIncrement: false,
      });
    },
  });
  return db;
};

export const saveImageToDB = async (fileName: string, image: ArrayBuffer) => {
  const db = await initDB();
  const uploadTime = new Date().getTime();
  await db.put(STORE_NAME, { fileName, image, uploadTime });
};

export const getImageFromDB = async (fileName: string) : Promise<ArrayBuffer | undefined> => {
  const db = await initDB();
  const data = await db.get(STORE_NAME, fileName);
  return data ? data.image : undefined;
};
