import {useDispatch} from "react-redux";
import {AppDispatch} from "../../store";
import {useEffect} from "react";
import {fetchLatestFacilityChatMessages, selectUserChatMessages} from "../../store/feature/chat";
import {useAppSelector} from "../index";

export const useFetchChatMessages = () => {
    const dispatch = useDispatch<AppDispatch>();
    const {activeStudentId, id} = useAppSelector((state) => state.configuration)

    useEffect(() => {
        if (activeStudentId !== -1) {
            dispatch(fetchLatestFacilityChatMessages({studentId: activeStudentId, userId: id}));
        }
    }, [dispatch, activeStudentId]);

    return useAppSelector((state) => selectUserChatMessages(state)) ;
}
