import {useTheme} from "@mui/material/styles";
import {Box, Stack, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {SubmitButton} from "./calendar/planchanges/SubmitButton";
import animations from "../../assets/gif/success2.gif"

type SuccessPros = {
    close: () => void
}
export const SuccessView = ({close} : SuccessPros) => {
    const theme = useTheme();
    const {t} = useTranslation();

    return (
        <Stack justifyContent={"center"} alignItems={"center"} sx={{width: '100%', p: 2, margin: '0 0 15px 0'}}>
            <Box sx={{width: '300px', height: '300px'}}>
                <img alt={'success'} src={animations} width={'300px'}/>
            </Box>
            <Typography style={{marginTop: '20px'}} color={theme.palette.success.contrastText}
                        variant={'h4'}>{t('widget.planChanges.mainMenu.success.title')}</Typography>
            <SubmitButton variant={'contained'} onClick={() => close()} style={{marginTop: '50px'}}>Ok</SubmitButton>
        </Stack>
    )
}
