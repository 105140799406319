import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {fetUserEmailPreferences, selectEmailPreference} from "../../store/feature/user";
import {AppDispatch} from "../../store";
import {useAppSelector} from "../index";

export const useFetchUserEmailPreferences = () => {
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(fetUserEmailPreferences());
    }, [dispatch]);

    return useAppSelector((state) => selectEmailPreference(state));
}
