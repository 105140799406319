import {Box, Button, Divider, IconButton, List, ListItem, Skeleton, Stack, Typography} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import React from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import {deleteStudentRequest, fetchStudentChangeRequests, StudentPlanChanges} from "../../../../store/feature/user";
import {useTheme} from "@mui/material/styles";
import RestaurantIcon from '@mui/icons-material/Restaurant';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import dayjs from "dayjs";
import {EVENT_TYPES} from "../../../../themes/eventTypes";
import {useFetchStudentChangeRequests} from "../../../../hooks/fetch/useFetchStudentChangeRequests";
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';

type Props = {
    goAbsence: () => void
    goMeal: () => void
    studentId: number
}
export const MainMenu = ({goAbsence, goMeal}: Props) => {
    const {t} = useTranslation();
    const [toDelete, setToDelete] = React.useState<number | null>(null);
    const studentId = useAppSelector((state) => state.configuration.activeStudentId)
    const dispatch = useAppDispatch();
    const theme = useTheme();
    const changeRequests = useFetchStudentChangeRequests();
    console.log(changeRequests)

    const fetchStudentRequests = () => {
        dispatch(fetchStudentChangeRequests({studentId: studentId}))
    }

    const handleDelete = (change: StudentPlanChanges) => {
        dispatch(deleteStudentRequest({studentId, planId: change.id})).then(() => {
            fetchStudentRequests();
        })
    }


    return (
        <>
            <Stack spacing={2}>
                <Typography variant={'body2'} sx={{fontSize: '1rem'}}>{t('widget.planChanges.absence.help')}</Typography>
                <Button variant={'contained'} startIcon={<CoPresentIcon />} sx={{borderRadius:50, color: 'white'}} onClick={goAbsence}>{t('widget.planChanges.absence.button.title')}</Button>
            </Stack>

            <Divider style={{margin: '20px 0'}}/>


            <Stack spacing={2}>
                <Typography variant={'h4'}>{t('widget.planChanges.planned.title')}:</Typography>
                <List>

                    {!changeRequests && (<Stack spacing={1}>
                        <Skeleton sx={{borderRadius: '8px'}} variant="rectangular" width={'100%'} height={50}/>
                        <Skeleton sx={{borderRadius: '8px'}} variant="rectangular" width={'100%'} height={50}/>
                        <Skeleton sx={{borderRadius: '8px'}} variant="rectangular" width={'100%'} height={50}/>
                    </Stack>)}
                    {changeRequests && changeRequests.length === 0 &&(
                        <Stack direction={"row"} alignItems={'center'} justifyContent={'center'} spacing={3}>
                            <ListAltOutlinedIcon sx={{stroke: "#ffffff", strokeWidth: 1, color: '#c8d3d5', width: '100px', height: '100px'}}/>
                            <Typography variant={'body2'} sx={{fontSize: '1.3rem', color: '#7d8491'}} >{t('widget.planChanges.noChanges.title')}</Typography>

                        </Stack>
                    )}
                    {changeRequests && changeRequests.map((change) => (
                        <ListItem key={change.id} sx={{ p: 0, border: `1px solid rgba(210,210,210,0.8)`, boxShadow: 'rgba(0, 0, 0, 0.1) 0 0 4px', marginBottom: '10px', borderRadius:'7px'}}>
                            <Stack direction={'row'} spacing={2} alignItems={'center'} sx={{width: '100%'}}>
                                <Box sx={{width: 50, height:'60px', backgroundColor: 'rgb(240,240,240)', textAlign: 'center'}}>
                                    {change.type === 'meal-cancel' && <RestaurantIcon sx={{color: EVENT_TYPES['cancelled-meals'].lightColor, height: '2em'}}/>}
                                    {change.type === 'absence' && <CoPresentIcon sx={{color: EVENT_TYPES['absence'].lightColor, height: '2em'}}/>}
                                </Box>
                                <Box sx={{width: {md:'300px'}, marginRight:'30px!important'}}>
                                    <Typography variant={'h5'}>{change.type === 'absence' ? 'Planned absence' : 'Meal menu changes'}</Typography>

                                    <Typography variant={'body2'} sx={{fontSize: '0.9em', fontWeight: 'bold'}}>{change.days.map((d) => dayjs(d).format('MMM D')).join(', ')}</Typography>
                                    {toDelete === change.id && (
                                        <Stack direction={"row"} sx={{position: 'absolute', backgroundColor: 'white', right: 8, top: 8}}>
                                            <Button sx={{color: theme.palette.error.main}} onClick={() => handleDelete(change)} variant={"text"}>Delete</Button>
                                            <Button onClick={() => setToDelete(null)} variant={"text"}>Cancel</Button>

                                        </Stack>
                                    )}
                                    {toDelete !== change.id && (
                                        <IconButton
                                            aria-label="close"
                                            onClick={() => setToDelete(change.id)}
                                            sx={{
                                                position: 'absolute',
                                                right: 8,
                                                top: 8,
                                                color: (theme) => theme.palette.grey[500],
                                            }}
                                        >
                                            <DeleteIcon/>
                                        </IconButton>
                                    )}

                                </Box>
                                <Box sx={{ textAlign: 'center'}}>
                                    <Typography variant={'body2'} sx={{fontSize: '0.6em', color: 'grey'}}>Created at</Typography>
                                    <Typography variant={'body2'} sx={{fontSize: '0.6em', color: 'grey'}}>{dayjs(change.createdOn).format('MMM D HH:mm')}</Typography>
                                </Box>
                            </Stack>

                        </ListItem>
                    ))}
                </List>
            </Stack>
        </>
    )
}
