import CloseIcon from '@mui/icons-material/Close';
import {Box, IconButton, Stack, Typography} from "@mui/material";
import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {useTranslation} from "react-i18next";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export const Notifications = () => {
    const dispatch = useAppDispatch();
    const notifications = useAppSelector((state) => state.notification.notifications);
    const {t} = useTranslation();

    useEffect(() => {
        if (notifications.length > 0) {
            setTimeout(function() {
                notifications.forEach(n => {
                    dispatch({type: '@notification/REMOVE_NOTIFICATION', id: n.id});
                })
            }, 100000);
        }

    }, [notifications]);

    return (
        <Box
            sx={{
                position: 'fixed',
                zIndex: 2299,
                backgroundColor: '#c41c1c',
                width: '100%',
                boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)',
                display: notifications.length > 0 ? 'block' : 'none',
            }}
        >
            {notifications.length > 0 && notifications.map((notification, index) => {
                return (
                    <Stack key={index} direction={'row'} sx={{width: '100%', height: '100px', }} alignItems={'center'} >
                        <Box sx={{p: 2, width: '80%'}}>
                            <Stack direction={'row'} alignItems={'center'} spacing={2}>
                                <ErrorOutlineIcon sx={{color: 'white'}}/>
                                <Box>
                                    <Typography variant={'h4'} sx={{color: 'white'}}>{t(notification.title)}</Typography>
                                    <Typography variant={'body2'} sx={{color: 'white'}}>{t(notification.text)}</Typography>
                                </Box>
                            </Stack>
                        </Box>
                        <Box sx={{width: '20%', p: 2}}><IconButton onClick={() => dispatch({type: '@notification/REMOVE_NOTIFICATION', id: notification.id})}><CloseIcon sx={{color: 'white'}}/></IconButton></Box>
                    </Stack>

                )
            })}

    </Box>
    );
}
