import {Reducer} from "redux";

export const ADD_NOTIFICATION = '@notification/ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = '@notification/REMOVE_NOTIFICATION';
export const REMOVE_ALL_NOTIFICATIONS = '@notification/REMOVE_ALL_NOTIFICATIONS';

export type AppNotification = {
    id: string,
    variant: string,
    title: string,
    text: string
}
export type NotificationAddAction = {
    type:  typeof ADD_NOTIFICATION;
    value: AppNotification
}

export type NotificationRemoveAction = {
    type: typeof REMOVE_NOTIFICATION
    id: string
}

export type NotificationsRemoveAction = {
    type: typeof REMOVE_ALL_NOTIFICATIONS
}

export type NotificationState = {
    notifications: AppNotification [] ,
}

export const initialState = {
    notifications: []
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

export const notificationReducer : Reducer<NotificationState, NotificationAddAction | NotificationRemoveAction | NotificationsRemoveAction> = (state = initialState, action: NotificationAddAction | NotificationRemoveAction | NotificationsRemoveAction): NotificationState => {

    switch (action.type) {
    case ADD_NOTIFICATION:
      return {
        ...state,
          notifications: [...state.notifications.filter((notification) => notification.id !== action.value.id), action.value]
      };
    case "@notification/REMOVE_NOTIFICATION":
        return {
            ...state,
            notifications: state.notifications.filter((notification) => notification.id !== action.id)
        }
    case "@notification/REMOVE_ALL_NOTIFICATIONS":
        return {
            ...state,
            notifications: []
        }
        default:
      return state;
  }
};
