import {Card, CardContent, Stack, Typography} from "@mui/material";
import {calendarEventTypes} from "../../../themes/eventTypes";
import React from "react";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";

export const CalendarLegend = () => {
    const theme = useTheme();
    const {t} = useTranslation();

    return (
        <Card sx={{
            minWidth: 350,
            maxHeight: 400,
            borderRadius: 7,
            [theme.breakpoints.down('md')]: {
                display: 'none'
            },
        }}>
            <CardContent>
                <Typography sx={{height: 40, marginTop: '5px'}} variant={'h4'}>{'Category'}</Typography>
                {calendarEventTypes.map((type) => (
                    <Stack key={type.name} sx={{height: 30}} direction={"row"} spacing={2}>
                        <div style={{width: 20, height: 20, backgroundColor: type.lightColor, borderRadius: 5}}></div>
                        <Typography key={type.name}  variant={'body1'}>{`${t(type.name)}`}</Typography>
                    </Stack>
                ))}
                <Stack sx={{height: 30}} direction={"row"} spacing={2}>
                    <div style={{width: 20, height: 20, border: `2px solid rgb(73,73,73)`, borderRadius: '50%'}}></div>
                    <Typography  variant={'body1'}>{`${t('eventType.multipleEvents')}`}</Typography>
                </Stack>
            </CardContent>
        </Card>
    )
}
