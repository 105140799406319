import React from "react";
import {Stack, Box, Typography} from "@mui/material";
import {Logo} from "../../Logo";
import {useTheme} from "@mui/material/styles";

export function SignInHeader() {
    const theme  = useTheme();

    return (
        <Stack textAlign={'center'} justifyContent={'center'}>
            <Box sx={{ p: 3 }} >
                  <Logo />
            </Box>

            <Typography color={theme.palette.secondary.main} gutterBottom sx={{ fontSize: '1.5rem' }} variant={ 'h3' }>
                Hi, Welcome Back
            </Typography>
            <Typography variant="caption" fontSize="16px" color={'#697586'} textAlign={'center' }>
                Enter your credentials to continue
            </Typography>
        </Stack>
    );
}
