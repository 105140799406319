import React, {Attributes} from "react";
import {Badge} from "@mui/material";
import {keyframes, SxProps} from "@mui/system";
import {Theme} from "@mui/material/styles";

interface Props  extends Attributes {
    children?: React.ReactNode;
    visible: boolean
    top?: number
    right?: number
    sx?: SxProps<Theme>
}

export const NotificationBadge = ({children, visible, sx,  top = 0, right = 0}: Props) => {

    const pulse = keyframes`
    0% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    70% {
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    }
`;

    return <Badge sx={{
        '& .MuiBadge-badge': {  animation: `${pulse} 3s infinite`, top: `${top}px`, right: `${right}px`},
        ...sx

    }} variant={'dot'} color={'error'} invisible={!visible}>
        {children}
    </Badge>

}
