import {fetchUserAttributes, updateUserAttribute} from 'aws-amplify/auth';
import {AppUser} from "../store/configurationReducer";

export const handleUpdateUserAttribute = async (attributeKey: string, value: string) => {
    try {
        await updateUserAttribute({
            userAttribute: {
                attributeKey,
                value
            }
        });
    } catch (error) {
        console.log(error);
    }
}

export const updateActiveUserIdInStorage = (id: number) => {
    const user = getAppUserFromLocalStorage();
    if (user) {
        user.activeStudentId = id;
        updateAppUserInLocalStorage(user);
    }
}

export const getAppUserFromLocalStorage = (): AppUser | null => {
    try {
        return localStorage.getItem('compass.user') ? JSON.parse(localStorage.getItem('compass.user') as string) as AppUser : null;
    } catch {
        localStorage.removeItem('compass.user');
        console.error("can not parse user from localstorage")
        return null;
    }
}

export const updateAppUserInLocalStorage = (user: AppUser): void => {
    try {
        localStorage.setItem('compass.user', JSON.stringify(user)) ;
    } catch {
        localStorage.removeItem('compass.user');
        console.error("can not update user in localstorage")
    }
}

export const fetchUserDetails = async () => {
    const userApp = getAppUserFromLocalStorage();
    if (userApp) {
        return Promise.resolve(userApp)
    }

    try {
        const attributes = await fetchUserAttributes();
        const studentIds = attributes['custom:child_id']?.split(',').map((id) => parseInt(id)) ?? [];
        const newUserApp: AppUser = {
            language: attributes.locale as string,
            id: attributes['sub'] as string,
            fullName: attributes.given_name + ' ' + attributes.family_name,
            activeStudentId: studentIds.length > 0 ? studentIds[0] : -2
        };
        if (studentIds.length > 0) {
            updateAppUserInLocalStorage(newUserApp);
        } else {
            localStorage.removeItem("compass.user");
        }

        return newUserApp;
    } catch (error) {
        console.log(error);
        localStorage.clear();
        window.location.reload();
        return null;
    }
}
