import {buildCustomSlice, createGenericAsyncThunk} from "../../../generic";
import {ChatItem, ChatItemDate, ChatItemMessage, PageableList} from "../../../types";
import dayjs from "dayjs";
import {createSelector} from "@reduxjs/toolkit";
import {AppState} from "../../index";
import {API_URL} from "../../../config";


export type ChatMessage = {
    message: string;
    createdOn: string;
    userFullName: string;
    userId: string;
}

const convertToChatItem = (data: PageableList<ChatMessage>, userId: string): PageableList<ChatItem> => {
    const result: PageableList<ChatItem> = {...data, content: [] as ChatItem[]}

    let startDay = '';
    const resultContent: ChatItem[] = [];
    for(let i = data.content.length - 1; i >= 0; i--) {
        let message = data.content[i];
        const messageDate = dayjs(message.createdOn);
        const messageDay = messageDate.format('MMM D');
        const [month, day] = messageDay.split(' ');
        if (messageDay !== startDay) {
            resultContent.push({type: 'date', day, month } as ChatItemDate);
            startDay = messageDay;
        }
        resultContent.push({type: 'message', message: message.message, time: messageDate.format('HH:mm'), author: message.userFullName, userMessage: message.userId === userId} as ChatItemMessage);
    }
    result.content = resultContent;

    return result;
};

export const fetchLatestFacilityChatMessages = createGenericAsyncThunk<PageableList<ChatItem>, { studentId: number, userId: string}>(
    'chats/fetchLatestFacilityChatMessages',
    async ({ studentId, userId }, { extra, rejectWithValue }) => {
        const response = await extra.ajax.get(`${API_URL}/api/public/v1/chat/${studentId}/messages?page=0&size=15`);
        const data: PageableList<ChatMessage> = await response.json();
        if (response.status !== 200) {
            return rejectWithValue({statusCode: response.status, response: data});
        }
        return convertToChatItem(data, userId);
    }
);

export const fetchMoreFacilityChatMessages = createGenericAsyncThunk<PageableList<ChatItem>, { studentId: number, userId: string, page: number, size: number}>(
    'chats/fetchMoreFacilityChatMessages',
    async ({ studentId, userId, page, size }, { extra, rejectWithValue }) => {
        const response = await extra.ajax.get(`${API_URL}/api/public/v1/chat/${studentId}/messages?page=${page}&size=${size}`);
        const data: PageableList<ChatMessage> = await response.json();
        if (response.status !== 200) {
            return rejectWithValue({statusCode: response.status, response: data});
        }
        return convertToChatItem(data, userId);
    }
);

export const sendChatMessage = createGenericAsyncThunk<void, { message: string, studentId  : number}>(
    'chats/sendMessage',
    async ({ studentId, message }, { extra, rejectWithValue }) => {
        const response = await extra.ajax['post'](`${API_URL}/api/public/v1/chat/${studentId}/messages`, { message });
        const data = await response.json();
        if (response.status !== 200) {
            return rejectWithValue({statusCode: response.status, response: data});
        }
    }
);

export const userChatMessagesSlice = buildCustomSlice<PageableList<ChatItem>>(
    'user/ChatFetchSlice',
    (builder) => {

        builder.addCase(fetchLatestFacilityChatMessages.fulfilled, (state, action) => {
            state.loaded = true;
            state.status = 'succeeded';
            //@ts-ignore
            state.data = action.payload;
            delete state.error;
        });

        builder.addCase(fetchMoreFacilityChatMessages.fulfilled, (state, action) => {
            state.loaded = true;
            state.status = 'succeeded';
            //@ts-ignore
            state.data = state.data ? {...action.payload, content: [...action.payload.content, ...state.data.content]} : action.payload;
            delete state.error;
        });
    }
);

export const userChatMessagesReducer = userChatMessagesSlice.reducer;
export const selectUserChatMessages = createSelector([(state: AppState) => state.chatMessages], (messages) => messages.data);



