import {
    Box,
    Divider, FormControlLabel,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography
} from "@mui/material";
import config from "../../../../config";
import {IconLogout, IconReload, IconSettings} from "@tabler/icons-react";
import {IOSSwitch} from "../../../../ui-component/widget/switcher";
import LanguageIcon from "@mui/icons-material/Language";
import {LanguageSwitcher} from "../LanguageSwitcher";
import MainCard from "../../../../ui-component/cards/MainCard";
import React from "react";
import {useTheme} from "@mui/material/styles";
import {useFetchUserEmailPreferences} from "../../../../hooks/fetch/useFetchUserEmailPreferences";
import {EmailPreference, updateUserEmailPreferences} from "../../../../store/feature/user";
import {signOut} from "aws-amplify/auth";
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import {useTranslation} from "react-i18next";

export const UserMenu = () => {
    const [selectedItem, setSelectedItem] = React.useState('');
    const userEmailPreferences = useFetchUserEmailPreferences();
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const {fullName} = useAppSelector(state => state.configuration);
    const {t} = useTranslation();

    const handleSignOut = () => {
        signOut()
            .then(() => {
                console.log("Clearing cache")
                localStorage.clear();
                window.location.reload();
            })
            .catch(err => console.log(err));
    }

    const handleReload = () => {
        if ('serviceWorker' in navigator) {
            caches.keys().then(function(cacheNames) {
                cacheNames.forEach(function(cacheName) {
                    caches.delete(cacheName).then(r => console.log('Removed App cache', r));
                });
            });
        }
        localStorage.removeItem('compass.user')
        console.log('Reloading APP');
        window.location.reload();
    }

    const updateEmailPreferences = (ep: EmailPreference) => {
        dispatch(updateUserEmailPreferences(ep));
    }
    return (
        <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
            <Box sx={{ p: 2, paddingBottom: 0 }}>
                <Stack>
                    <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="h4">{t('Hello')},</Typography>
                        <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                            {fullName}
                        </Typography>
                    </Stack>
                    <Typography variant="subtitle2">Parent access</Typography>
                </Stack>

                <Divider />
            </Box>
            <Box sx={{ p: 1, paddingTop: 0 }}>

                <List
                    component="nav"
                    sx={{
                        width: '100%',
                        maxWidth: 350,
                        minWidth: 300,
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: '10px',
                        '& .MuiListItemButton-root': {
                            mt: 0.5
                        }
                    }}
                >
                    <ListItemButton
                        sx={{ borderRadius: `${config.borderRadius}px` }}
                        selected={selectedItem === 'account'}
                        onClick={() => setSelectedItem(selectedItem === 'account' ? '' : 'account')}
                    >
                        <ListItemIcon>
                            <IconSettings stroke={1.5} size="1.3rem" />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="body2">{t('menu.settings')}</Typography>} />
                    </ListItemButton>
                    {selectedItem === 'account' && (
                        <ListItem>
                            <Box>
                                <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>Email Preferences:</Typography>
                                <Box sx={{ padding: '10px' }}>
                                    <FormControlLabel
                                        control={ <IOSSwitch name={"invoice"}  checked={userEmailPreferences.invoiceViaEmail} onChange={(v) => updateEmailPreferences({ ...userEmailPreferences, invoiceViaEmail: v.target.checked }) }/>}
                                        label="Invoices via Email"
                                        sx={{'& .MuiFormControlLabel-label': {ml: 2}}}
                                    />
                                </Box>
                                <Box sx={{ padding: '10px' }}>
                                    <FormControlLabel
                                        control={ <IOSSwitch name={"announce"}  checked={userEmailPreferences.announceViaEmail} onChange={(v) => updateEmailPreferences({ ...userEmailPreferences, announceViaEmail: v.target.checked })}/>}
                                        label="Announcements via Email"
                                        sx={{'& .MuiFormControlLabel-label': {ml: 2}}}
                                    />
                                </Box>
                            </Box>

                        </ListItem>
                    )}
                    <ListItemButton
                        sx={{ borderRadius: `${config.borderRadius}px` }}
                        selected={selectedItem === 'language'}
                        onClick={() => setSelectedItem(selectedItem === 'language' ? '' : 'language')}
                    >
                        <ListItemIcon>
                            <LanguageIcon width={'1.3rem'} height={'1.3rem'} sx={{ stroke: "#ffffff", strokeWidth: 1 }} />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="body2">{t('menu.language')}</Typography>} />
                    </ListItemButton>
                    {selectedItem === 'language' && (
                        <ListItem>
                            <LanguageSwitcher/>
                        </ListItem>
                    )}
                    <ListItemButton
                        sx={{ borderRadius: `${config.borderRadius}px` }}
                        onClick={handleReload}
                    >
                        <ListItemIcon>
                            <IconReload stroke={1.5} size="1.3rem" />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="body2">{t('menu.reload')}</Typography>} />
                    </ListItemButton>
                    <ListItemButton
                        sx={{ borderRadius: `${config.borderRadius}px` }}
                        onClick={handleSignOut}
                    >
                        <ListItemIcon>
                            <IconLogout stroke={1.5} size="1.3rem" />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="body2">{t('menu.logout')}</Typography>} />
                    </ListItemButton>
                </List>
            </Box>
        </MainCard>
    )
}
