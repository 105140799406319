import React from "react";
import {deepOrange} from "@mui/material/colors";
import {Avatar, Box, Stack} from "@mui/material";
import {styled, useTheme} from "@mui/material/styles";

const MessageTimeStampRight = styled(Box)(() => ({
    position: "absolute",
    height: '15px',
    fontSize: ".85em",
    fontWeight: "300",
    right: "6px"
}))

const MessageAuthor = styled(Box)(({theme}) => ({
    minWidth: '50px',
    whiteSpace: 'nowrap',
    paddingRight: '20px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette.primary.dark,
    fontSize: '11px',
    height: '25px'
}))

const MessageBlue = styled(Box)(({theme}) => ({
    position: "relative",
    marginLeft: "20px",
    marginBottom: "10px",
    padding: "10px",
    paddingBottom: "15px",
    backgroundColor: "#ecebeb",
    maxWidth: theme.breakpoints.down("sm") ? "250px" : "500px",
    textAlign: "left",
    font: "400 .9em 'Open Sans', sans-serif",
    border: "1px solid #ecebeb",
    borderRadius: "10px",
    "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "15px solid #ecebeb",
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "0",
        left: "-15px"
    },
    "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: "17px solid #ecebeb",
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "-1px",
        left: "-17px"
    }
}))

const MessageOrange = styled(Box)(({theme}) => ({
    minWidth: "70px",
    position: "relative",
    marginRight: "20px",
    marginBottom: "10px",
    padding: "10px",
    paddingBottom: "15px",
    color: 'white',
    backgroundColor: theme.palette.primary.dark,
    [theme.breakpoints.up("xs")]: {
        maxWidth: '250px'
    },
    [theme.breakpoints.up("md")]: {
        maxWidth: '500px'
    },
    textAlign: "left",
    font: "400 .9em 'Open Sans', sans-serif",
    border: "1px solid #ebfff2",
    borderRadius: "10px",
    "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: `15px solid ${theme.palette.primary.dark}`,
        borderLeft: "15px solid transparent",
        borderRight: "15px solid transparent",
        top: "0",
        right: "-15px"
    },
    "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTop: `17px solid ${theme.palette.primary.dark}`,
        borderLeft: "16px solid transparent",
        borderRight: "16px solid transparent",
        top: "0",
        right: "-14px"
    }
}))

type MessageProps = {
    message: string;
    timestamp: string;
    photoURL?: string;
    displayName: string;
}


export const MessageLeft = ({message = "no message", timestamp = "", displayName, photoURL}: MessageProps) => {
    const theme = useTheme();
    const nameArray = displayName.split(" ");
    const name = nameArray[0][0].toUpperCase() + (nameArray.length > 1 ? nameArray[1][0] : '').toUpperCase();
    return (
        <>
            <Box sx={{display: "flex"}}>
                <Avatar
                    sx={{
                        color: '#fff',
                        backgroundColor: '#c6cedcbd',
                        width: theme.spacing(4),
                        height: theme.spacing(4),
                        fontSize: "1rem",
                    }}

                >{name}</Avatar>
                <div>
                    <MessageBlue>
                        <MessageAuthor>{displayName}</MessageAuthor>
                        <div>
                            <p style={{padding: 0, margin: 0, whiteSpace: 'pre-line', wordWrap: 'break-word'}}>{message}</p>
                        </div>
                        <Stack sx={{marginTop: '5px', marginRight:'10px'}} direction={'row'} justifyContent={'space-between'}>
                            <MessageTimeStampRight>{timestamp}</MessageTimeStampRight>
                        </Stack>
                    </MessageBlue>
                </div>
            </Box>
        </>
    );
};


export const MessageRight = ({message = "no message", timestamp = "", photoURL, displayName}: MessageProps) => {
    const theme = useTheme()
    return (
        <Box sx={{
            display: "flex",
            justifyContent: "flex-end"
        }}>
            <div style={{
                display: "flex",
                justifyContent: "flex-end"
            }}>
            <MessageOrange>
                <p style={{padding: '3px 0', margin: 0, whiteSpace: 'pre-line', wordWrap: 'break-word'}}>{message}</p>
                <MessageTimeStampRight>{timestamp}</MessageTimeStampRight>
            </MessageOrange>
            </div>
                <Avatar
                alt={displayName}
                sx={{
                    color: theme.palette.getContrastText(deepOrange[500]),
                    backgroundColor: '#a2c0d7',
                    width: theme.spacing(4),
                    height: theme.spacing(4)
                }}
                src={photoURL}
            ></Avatar>

        </Box>
    );
};
