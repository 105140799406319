import {Box, Button, Card, Chip, Divider, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {selectStudentsDetails, StudentData} from "../../../store/feature/user";
import {useAppSelector} from "../../../hooks";
import PaymentsIcon from '@mui/icons-material/Payments';
import dayjs from "dayjs";

import circles from '../../../assets/images/circles.svg'
import {useTranslation} from "react-i18next";
import {NotificationBadge} from "../../NotificationBadge";
import {EventInvoice, SchoolEvent} from "../../../store/feature/event";

type Props = {
    event: SchoolEvent<EventInvoice>
}
export const InvoiceListView = ({event} : Props) => {

    const { language } = useAppSelector((state) => state.configuration);
    const {t} = useTranslation();
    const {activeStudentId} = useAppSelector((state) => state.configuration);
    const students = useAppSelector((state) => selectStudentsDetails(state));
    const[student, setStudent] = useState<StudentData | undefined>(undefined);

    useEffect(() => {
        if(activeStudentId && students) {
            const st = students.filter(s => s.id === activeStudentId );
            if(st.length > 0) {
                setStudent(st[0])
            }
        }
    }, [students, activeStudentId])

    const date = dayjs(`${event.event.year}-${event.event.month}-01`).format('MMMM YYYY');
    const [month, year] = date.split(' ');
    return (
        <Box >

            <Typography sx={{fontSize: '1.1rem', fontWeight: '600', paddingBottom: '10px'}}  variant={'h5'}>{t('event.invoice.title')}</Typography>

            <Stack sx={{width: '100%'}} justifyContent={'space-between'} direction={'row'} >
                <Chip label={t('event.invoice.name')} sx={{textTransform: 'capitalize', backgroundImage: `url(${circles})`, padding: '2px 4px'}} icon={<PaymentsIcon color={'action'}/>}></Chip>
                <NotificationBadge visible={!event.read}/>
            </Stack>
            <Box sx={{margin: '20px 0'}}>
                <Stack textAlign={'center'} justifyContent={'center'}>
                    <Card sx={{backgroundImage: `url(${circles})`, backgroundPosition: 'center', padding: '10px', width: {xs: '100%', md:'500px'}, margin: '0 auto', borderRadius: '7px', boxShadow: '0 2px 14px 0 rgb(32 40 45 / 8%)'}}>
                        <Box sx={{padding: 2}}>
                            {student && <img alt={student.fullName} src={student.avatarImage} style={{width: '70px', borderRadius: '50%', border: '1px solid #43b9b8'}}/> }
                        </Box>
                        <Divider sx={{borderBottomWidth: '3px', borderColor: 'white'}}/>
                        <Box sx={{padding: 2}}>
                            <Typography variant={'body2'} sx={{fontSize: '1.5rem', textTransform: 'capitalize'}}>{t(month) + ' ' + year}</Typography>
                            <Typography variant={'body2'} sx={{fontSize: '1.5rem', fontWeight: '700'}}>{event.event.amount} {event.event.currency}</Typography>
                        </Box>
                        <Box sx={{width: '85%', margin: '0 auto', backgroundColor: '#f0f0f0', padding: 2, borderRadius: '7px', marginBottom: '30px'}}>
                    {event.event.items.map((item, index) => {
                        return (
                            <Box key={index}>
                            <Stack direction={'row'} alignItems={'start'} alignContent={'start'} justifyContent={'space-between'} spacing={1} >
                                <Box >
                                    {language.toUpperCase() === 'PL' && (<Typography sx={{textAlign: 'left'}} variant={'body2'}>{item.namePL}</Typography>)}
                                    {language.toUpperCase() === 'EN' && (<Typography sx={{textAlign: 'left'}} variant={'body2'}>{item.nameEN}</Typography>)}
                                </Box>

                                <Typography variant={'body2'} sx={{ wordBreak:'keep-all'}} style={{marginLeft: '25px'}}>{item.subTotal.toLocaleString()}{event.event.currency}</Typography>
                            </Stack>
                                { event.event.items.length - 1 !== index &&  (<Divider sx={{borderColor: '#c2e0fa', margin: '5px 0'}}/>)}
                            </Box>
                        )
                    })}
                        </Box>
                        <Divider sx={{borderBottomWidth: '1px', borderColor: 'white'}}/>
                        <Button variant={"text"} sx={{fontSize: '1.1rem'}}>{t('event.invoice.details')}</Button>
                    </Card>
                </Stack>
            </Box>
        </Box>
    )
}
