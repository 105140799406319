import {Box, Stack, Typography} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import {EventMessage, SchoolEvent} from "../../../store/feature/event";

type Props = {
    event: SchoolEvent<EventMessage>
}
export const AnnounceCard = ({event}: Props ) => {
    return (
        <Box sx={{wordWrap: 'break-word'}} >
            <Stack direction={"row"} justifyContent={"space-between"} sx={{mb: 2}}>
                <Typography variant={'h5'} sx={{
                    color: '#7d7d7d',
                    fontSize: '0.75rem',
                    fontWeight: 600
                }}>{event.sender}</Typography>
                <Typography variant={'h5'} sx={{
                    color: '#7d7d7d',
                    fontSize: '0.75rem',
                    fontWeight: 600
                }}>{dayjs(event.createdOn).format('D MMM HH:mm')}</Typography>
            </Stack>
            <Box sx={{marginBottom:'35px', mt: '25px'}}>
                <Box component={'span'} sx={{fontWeight: '600', color: 'rgb(73,73,73)', fontSize:'1.4rem', lineHeight: '1.2em'}}>{event.subject}</Box>

            </Box>

            <Box sx={{
                '& .content': {
                    fontSize: '1rem',
                    lineHeight: '1.5rem'
                },
                '& .content li': {
                    margin: '5px 0',
                    lineHeight: '1.3rem'
                }
            }}>
                <div className={'content'} dangerouslySetInnerHTML={{__html: event.event.message}}/>
            </Box>

        </Box>
    )
}
