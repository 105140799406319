import {Box, Divider, List, ListItem, ListItemButton, Stack, Typography} from "@mui/material";
import React from "react";
import {PageableList} from "../../../types";
import {useInfiniteScroll} from "../../../hooks/useInfiniteScroll";
import {useOutletContext} from "react-router";
import {ScrollLoader} from "../../ScrollLoader";
import {Gallery, SchoolEvent} from "../../../store/feature/event";
import {LoadableImagePreview} from "../../LoadableImagePreview";
import dayjs from "dayjs";
import {IconLibraryPhoto} from "@tabler/icons-react";
import {NotificationBadge} from "../../NotificationBadge";

type Props = {
    events: PageableList<SchoolEvent<Gallery>>
    onEventClick: (event: SchoolEvent<Gallery>) => void
    loadMore: (page: number) => Promise<any>
}

export const StudentGalleryList = ({events, onEventClick, loadMore}: Props) => {

    // @ts-ignore
    const {mainRef} = useOutletContext();


     const { loading } = useInfiniteScroll<HTMLUListElement>({
        fetchMoreData: loadMore,
        totalPage:  events.totalPages - 1,
        offsetContainerHeight: 100,
        direction: 'down',
        containerRef: mainRef
    });

    return (
        <>
        <List sx={{ width: '100%' }}  >
            {events && events.content.length > 0 && events.content.map((event, index) => (
                <ListItem key={index} alignItems="flex-start" sx={{backgroundColor: 'white', marginBottom:'20px', borderRadius: '10px', padding: 0, boxShadow: '0 8px 16px #0000000d'}}>
                    <ListItemButton sx={{padding: '0'}} onClick={() => onEventClick(event)}>
                        <Box sx={{ width: '100%'}}>
                        {event.event.photos.length >=  3 && (
                            <>
                                <Stack direction={'row'} sx={{ width: '100%', height: {xs: '250px', md: '450px'}}} alignContent={'start'} >
                                    <LoadableImagePreview src={event.event.photos[0].path} imgStyle={{borderTopLeftRadius:'8px'}} sx={{marginRight: '2px'}} width={'60%'} height={'100%'}/>
                                    <Stack sx={{width: '40%', maxHeight: {xs: '250px', md: '450px'}}} >
                                        <LoadableImagePreview src={event.event.photos[1].path} sx={{pb: '1px'}} imgStyle={{borderTopRightRadius:'8px'}} width={'100%'} height={'50%'}/>
                                        <LoadableImagePreview src={event.event.photos[2].path} sx={{pt: '1px'}} width={'100%'} height={'50%'}/>
                                    </Stack>
                                </Stack>

                            </>
                        )}
                            <Stack sx={{p: 2}} spacing={3}>
                                <Stack direction={"row"} justifyContent={"space-between"}>
                                    <Typography variant={'h5'} sx={{
                                        color: '#7d7d7d',
                                        fontSize: '0.75rem',
                                        fontWeight: 600
                                    }}>{event.sender}</Typography>
                                    <Typography variant={'h5'} sx={{
                                        color: '#7d7d7d',
                                        fontSize: '0.75rem',
                                        fontWeight: 600
                                    }}>{dayjs(event.createdOn).format('D MMM HH:mm')}</Typography>
                                </Stack>
                                <Stack direction={'row'} sx={{width: '100%'}} alignItems={'center'}>
                                    <Typography sx={{width: '100%', mr:1}} variant={'h3'}>{event.subject}</Typography>
                                    <NotificationBadge visible={!event.read}/>
                                </Stack>

                                <div className={'content'} dangerouslySetInnerHTML={{__html: event.event.message}}/>

                                <Divider/>
                                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                    <IconLibraryPhoto color={'#f1428a'} stroke={'1.3'} size={'2.1em'}/>
                                    <Typography variant={'h5'}>{event.event.photos.length}</Typography>
                                </Stack>
                            </Stack>
                        </Box>
                    </ListItemButton>
                </ListItem>
            ))}

        </List>
            <ScrollLoader show={loading} direction={'down'}/>
        </>
    )
}
