// material-ui

import {CSSProperties} from "react";

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //
type Props = {
    width?: string
    style?: CSSProperties
}
export const Logo = ({width = '50', style}: Props ) => {

  return (
    <img src="/compass-logo.webp" alt="Compass" style={style} width={width} />
  );
};


