import {buildCustomSlice, createGenericAsyncThunk} from "../../../generic";
import {API_URL} from "../../../config";
import {createSelector} from "@reduxjs/toolkit";
import {AppState} from "../../index";
import {PageableList} from "../../../types";

export type EventMessage = {
    message: string
}

export type Image = {
    path: string
}

export type Gallery = {
    photos: Image[]
    message: string
}

export type EventInvoice = {
    issueDate: string
    dueDate: string
    year: number,
    month: number,
    currency: string
    amount: string
    invoiceId: string
    invoiceReference: string
    items: {
        namePL: string,
        nameEN: string,
        extra: string,
        subTotal: number,
    }[]
}

export type SchoolEvent<T> = {
    id: number;
    createdOn: string;
    sender: string;
    subject: string;
    type: string;
    event: T;
    prioritized: boolean;
    read: boolean;
}

export const fetchLatestStudentEvents = createGenericAsyncThunk<PageableList<SchoolEvent<EventMessage | EventInvoice>>, { studentId: number, language: string}>(
    'events/fetchLatestStudentEvents',
    async ({ studentId, language }, { extra, rejectWithValue }) => {
        const response = await extra.ajax.get(
            `${API_URL}/api/public/v1/students/${studentId}/events?page=0&size=10`,
            {
                headers: {
                    'Accept-Language': language
                }
            }
        );
        const data: PageableList<SchoolEvent<EventMessage | EventInvoice>> = await response.json();
        if (response.status !== 200) {
            return rejectWithValue({statusCode: response.status, response: data});
        }
        return data;
    }
);

export const fetchMoreStudentEvents = createGenericAsyncThunk<PageableList<SchoolEvent<EventMessage | EventInvoice>>, { studentId: number, language: string, page: number, size: number}>(
    'events/fetchMoreStudentEvents',
    async ({ studentId, language, page, size }, { extra, rejectWithValue }) => {
        const response = await extra.ajax.get(`${API_URL}/api/public/v1/students/${studentId}/events?page=${page}&size=${size}`,{
            headers: {
                'Accept-Language': language
            }
        });
        const data: PageableList<SchoolEvent<EventMessage | EventInvoice>> = await response.json();
        if (response.status !== 200) {
            return rejectWithValue({statusCode: response.status, response: data});
        }
        return data;
    }
);

export const fetchLatestStudentGalleries = createGenericAsyncThunk<PageableList<SchoolEvent<Gallery>>, { studentId: number, language: string}>(
    'events/fetchLatestStudentGalleries',
    async ({ studentId, language }, { extra, rejectWithValue }) => {
        const response = await extra.ajax.get(
            `${API_URL}/api/public/v1/students/${studentId}/galleries?page=0&size=10`,
            {
                headers: {
                    'Accept-Language': language
                }
            }
        );
        const data: PageableList<SchoolEvent<Gallery>> = await response.json();
        if (response.status !== 200) {
            return rejectWithValue({statusCode: response.status, response: data});
        }
        return data;
    }
);

export const fetchMoreStudentGalleries = createGenericAsyncThunk<PageableList<SchoolEvent<Gallery>>, { studentId: number, language: string, page: number, size: number}>(
    'events/fetchMoreStudentGalleries',
    async ({ studentId, language, page, size }, { extra, rejectWithValue }) => {
        const response = await extra.ajax.get(`${API_URL}/api/public/v1/students/${studentId}/galleries?page=${page}&size=${size}`,
            {
                headers: {
                    'Accept-Language': language
                }
            });
        const data: PageableList<SchoolEvent<Gallery>> = await response.json();
        if (response.status !== 200) {
            return rejectWithValue({statusCode: response.status, response: data});
        }
        return data;
    }
);

export const markAsReadStudentEvent = createGenericAsyncThunk<void, { eventId: number }>(
    'events/markAsReadStudentEvent',
    async ({ eventId }, { extra, rejectWithValue }) => {
        const response = await extra.ajax.put(`${API_URL}/api/public/v1/events/${eventId}/read`);
        const data = await response.json();
        if (response.status !== 200) {
            return rejectWithValue({statusCode: response.status, response: data});
        }
    }
);

export const studentEventsSlice = buildCustomSlice<PageableList<SchoolEvent<EventMessage | EventInvoice>>>(
    'events/studentEventsSlice',
    (builder) => {

        builder.addCase(fetchLatestStudentEvents.fulfilled, (state, action) => {
            state.loaded = true;
            state.status = 'succeeded';
            //@ts-ignore
            state.data = action.payload;
            delete state.error;
        });

        builder.addCase(fetchMoreStudentEvents.fulfilled, (state, action) => {
            state.loaded = true;
            state.status = 'succeeded';
            //@ts-ignore
            state.data = state.data ? {...action.payload, content: [...state.data.content,...action.payload.content ]} : action.payload;
            delete state.error;
        });
    }
);

export const studentEventsReducer = studentEventsSlice.reducer;
export const selectStudentEvents = createSelector([(state: AppState) => state.events], (messages) => messages.data);


export const studentGalleriesSlice = buildCustomSlice<PageableList<SchoolEvent<Gallery>>>(
    'events/studentGalleriesSlice',
    (builder) => {

        builder.addCase(fetchLatestStudentGalleries.fulfilled, (state, action) => {
            state.loaded = true;
            state.status = 'succeeded';
            //@ts-ignore
            state.data = action.payload;
            delete state.error;
        });

        builder.addCase(fetchMoreStudentGalleries.fulfilled, (state, action) => {
            state.loaded = true;
            state.status = 'succeeded';
            //@ts-ignore
            state.data = state.data ? {...action.payload, content: [...state.data.content,...action.payload.content ]} : action.payload;
            delete state.error;
        });
    }
);

export const studentGalleriesReducer = studentGalleriesSlice.reducer;
export const selectStudentGalleries = createSelector([(state: AppState) => state.galleries], (messages) => messages.data);


