import {Reducer} from "redux";
import {updateAppUserInLocalStorage} from "../utils/auth";

export const SET_ACTIVE_STUDENT_ID = '@configuration/SET_ACTIVE_STUDENT_ID';
export const SET_LANGUAGE = '@configuration/SET_LANGUAGE';
export const SET_APP_USER = '@configuration/SET_APP_USER';
export const SET_SHOW_SUB_MENU = '@configuration/SET_SHOW_SUB_MENU';


export type AppUser = {
    id: string,
    fullName: string,
    activeStudentId: number,
    language: string,
}

export type AppUserConfigurationState = {
    id: string,
    fullName: string,
    activeStudentId: number,
    language: string,
    showSubMenu: boolean
}

export const initialState = {
    id: '',
    fullName: '',
    activeStudentId: -1,
    language: 'EN',
    showSubMenu: false
};


export type ConfigurationAction = {
    type: typeof SET_ACTIVE_STUDENT_ID | typeof SET_LANGUAGE  | typeof SET_SHOW_SUB_MENU | typeof SET_APP_USER;
    value: number | string | AppUser
}


const getState = (s: AppUserConfigurationState) => {
    updateAppUserInLocalStorage(s);
    return s;
}
// ==============================|| CUSTOMIZATION REDUCER ||============================== //

export const configurationReducer : Reducer<AppUserConfigurationState, ConfigurationAction> = (state = initialState, action: ConfigurationAction): AppUserConfigurationState => {
    switch (action.type) {
    case SET_ACTIVE_STUDENT_ID:
        return getState({
            ...state,
            activeStudentId: action.value as number
        });
    case SET_APP_USER:
        const user = action.value  as AppUser;
        return getState({
            ...state,
            id: user.id,
            fullName: user.fullName,
            language: user.language,
            activeStudentId: user.activeStudentId
        });
    case SET_LANGUAGE:
        return getState({
            ...state,
            language: action.value as string
        })
    case SET_SHOW_SUB_MENU:
        return getState({
            ...state,
            showSubMenu: Boolean(action.value).valueOf()
        })
    default:
      return state;
  }
};
