import { Box, TextField } from '@mui/material';
import { Controller, UseFormReturn } from 'react-hook-form';
import * as React from 'react';
import { SxProps } from '@mui/system';
import { Theme } from '@mui/material/styles';
import { InputBaseProps } from '@mui/material/InputBase';

type TextFieldProps = {
  name: string;
  label: string;
  required?: boolean;
  shrinkLabel?: boolean;
  placeHolder?: string;
  disabled?: boolean;
  rows?: number;
  form: UseFormReturn;
  sx?: SxProps<Theme>;
  size?: 'small' | 'medium';
  variant?: 'standard' | 'outlined' | 'filled';
  inputProps?: InputBaseProps['inputProps'];
};
export const InputField = ({ name, rows, label, placeHolder, disabled, shrinkLabel, form, required, sx, size = 'medium', variant = 'outlined', inputProps }: TextFieldProps) => {
  return (
    <Box sx={{ padding: '10px 0', width: '100%' }}>
      <Controller
        name={name}
        control={form.control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <TextField
            helperText={error ? error.message : null}
            error={!!error}
            multiline
            InputLabelProps={shrinkLabel ? { shrink: true } : undefined}
            onChange={onChange}
            required={required}
            disabled={disabled}
            placeholder={placeHolder}
            value={value}
            rows={rows}
            sx={sx}
            fullWidth
            label={label}
            size={size}
            variant={variant}
            inputProps={inputProps}
          />
        )}
      />
    </Box>
  );
};
