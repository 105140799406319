import {useTheme} from "@mui/material/styles";
import {Card, CardContent, Stack} from "@mui/material";
import {StudentDateCalendar} from "../../ui-component/widget/calendar/StudentDateCalendar";
import {PlanChanges} from "../../ui-component/widget/calendar/planchanges/PlanChanges";
import {StudentEvents} from "../../ui-component/widget/calendar/StudentEvents";
import React, {useEffect, useState} from "react";
import {CalendarLegend} from "../../ui-component/widget/calendar/CalendarLegend";
import {getDateOrToday, getYearMonth} from "../../utils/misc";
import {Dayjs} from "dayjs";
import {Loader} from "../../ui-component/Loader";
import {fetchCalendarEvents, selectCalendarEvents} from "../../store/feature/calendar";
import {useAppSelector} from "../../hooks";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../store";
import {useSearchParams} from "react-router-dom";

export const CalendarPage = () => {
    const theme = useTheme();
    const [searchParams] = useSearchParams();
    const calendarEvents = useAppSelector(selectCalendarEvents);
    const [selectedDate, setSelectedDate] = useState<Dayjs | string>(getDateOrToday(searchParams.get('date')));
    const {activeStudentId, language} = useAppSelector((state) => state.configuration);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(fetchCalendarEvents({studentId: activeStudentId, language, yearMonth: getYearMonth(selectedDate)}))
    }, [selectedDate, activeStudentId, language]);

    return (

        <div style={{padding: '20px 20px 40px 20px'}}>
            {!calendarEvents && <Loader/>}
            {calendarEvents && (
                <Stack spacing={{ xs: 2, sm: 4, md: 4 }} justifyContent={"center"}  direction={{ xs: 'column', sm: 'row' }} >
                    <Stack spacing={3}>
                        <Card sx={{minWidth: 275, maxHeight: 400, borderRadius: 7}}>
                            <CardContent sx={{padding: 0}} >
                                <StudentDateCalendar calendarEvents={calendarEvents} selectedDate={selectedDate} setSelectedDate={setSelectedDate}/>
                                <PlanChanges calendarEvents={calendarEvents} selectedDate={selectedDate} setSelectedDate={setSelectedDate}/>
                            </CardContent>
                        </Card>
                        <CalendarLegend/>
                    </Stack>
                    <Card sx={{
                        padding: {xs:'20px 10px 10px 10px', sm: '30px', md: '30px'},
                        paddingTop: 2,
                        borderRadius: 7,
                        [theme.breakpoints.down('lg')]: {
                            width: '100%'
                        },

                        [theme.breakpoints.up('lg')]: {
                            minWidth: '800px'
                        },
                        maxWidth: '800px'
                    }}>
                        <StudentEvents calendarEvents={calendarEvents} selectedDate={selectedDate} setSelectedDate={setSelectedDate}/>
                    </Card>

                </Stack>
            )}
        </div>
    )
}
