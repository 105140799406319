import {AppDispatch} from "./store";

export const initPWACache = (dispatch: AppDispatch) => {
        if ("serviceWorker" in navigator) {
            // Register a service worker hosted at the root of the
            // site using the default scope.
            navigator.serviceWorker.register(`${window.location.origin}/app-sw.js`, {scope: '/'}).then(
                (registration) => {
                    console.log("Service worker registration succeeded:", registration);
                    const channel = new BroadcastChannel('sw-messages');
                    channel.addEventListener('message', event => {
                        console.log('Received', event.data);
                        if (event.data.type === 'fetchError') {
                            if (event.data.error?.name === 'TypeError') {
                                dispatch({type: '@notification/ADD_NOTIFICATION', value: {id: 'connection', variant: 'error', title: 'error.notification.title', text: 'error.notification.connection'}})
                            }
                        }
                    });
                },
                (error) => {
                    console.error(`Service worker registration failed: ${error}`);
                },
            );
        } else {
            console.error("Service workers are not supported.");
        }
}
