import {useTheme} from "@mui/material/styles";
import {Box, Grid, Stack, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {fetchStudentsDetails, selectStudentsDetails} from "../../store/feature/user";
import {EmptyBox} from "../../ui-component/widget/EmptyBox";
import {Loader} from "../../ui-component/Loader";
import {
    EventInvoice,
    EventMessage,
    fetchLatestStudentGalleries,
    fetchMoreStudentGalleries,
    Gallery,
    markAsReadStudentEvent,
    SchoolEvent
} from "../../store/feature/event";
import {useAppDispatch, useAppSelector} from "../../hooks";
import {PageableList} from "../../types";
import {DialogPage} from "../../ui-component/widget/DialogPage";
import {useTranslation} from "react-i18next";
import {useFetchStudentGalleries} from "../../hooks/fetch/useFetchStudentGalleries";
import {StudentGalleryList} from "../../ui-component/widget/events/StudentGalleryList";
import {fetchImage} from "../../utils/files";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import Lightbox from "yet-another-react-lightbox";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Download from "yet-another-react-lightbox/plugins/download";
import {ImagePreview} from "../../ui-component/ImagePreview";
import dayjs from "dayjs";
import ShareImages from "../../ui-component/widget/ShareFiles";

export const GalleryPage = () => {
    const theme = useTheme();
    const {t} = useTranslation();
    const [open, setOpen] = React.useState(false);
    const {activeStudentId, language} = useAppSelector((state) => state.configuration);
    const studentData = useAppSelector((state) => selectStudentsDetails(state));
    const [event, setEvent] = React.useState<SchoolEvent<Gallery> | undefined>(undefined);
    const [activeImageIndex, setActiveImageIndex] = useState<number>(-1);
    const [loadedImages, setLoadedImages] = useState<Record<string, string> | undefined>()
    const dispatch = useAppDispatch();
    const loadMore = (page: number) => {
        return dispatch(fetchMoreStudentGalleries({studentId: activeStudentId, language, page, size: 10}))
    }
    const events = useFetchStudentGalleries();

    const handleClose = () => {
        setOpen(false);
        setEvent(undefined);
    };

    useEffect(() => {
        if (event) {
            const promises = event.event.photos.map((p) =>
                fetchImage(p.path).then((img) =>
                    setLoadedImages((prevLoadedImages) => ({
                        ...prevLoadedImages,
                        [p.path]: img
                    }))
                )
            );

            Promise.allSettled(promises).then(() => {
                console.log('loaded');
            });
        } else {
            if (loadedImages) {
                Object.values(loadedImages).forEach(url => url && URL.revokeObjectURL(url));
                setLoadedImages(undefined);
            }
        }
    }, [event]);

    useEffect(() => {
        if (studentData && activeStudentId) {
            studentData.filter((s) => s.id === activeStudentId).forEach((s) => {
                if(!s.eventsRead) {
                    dispatch(fetchLatestStudentGalleries({studentId: activeStudentId, language}))
                }
            })
        }
    }, [studentData, activeStudentId]);


    const handleOpen = (event: SchoolEvent<Gallery>) => {
        setEvent(event);
        setOpen(true);
        if(!event.read) {
            dispatch(markAsReadStudentEvent({eventId: event.id}))
                .then(()  => dispatch(fetchLatestStudentGalleries({studentId: activeStudentId, language})))
                .then((resp) => {
                    const newEvents = resp.payload as PageableList<SchoolEvent<EventMessage | EventInvoice>>;
                    if(newEvents.content.filter((e) => e.read === false ).length === 0) {
                        dispatch(fetchStudentsDetails());
                    }
                })
        }
    };

    return (
        <Box sx={{padding: '20px'}}>
            <DialogPage open={open} handleClose={handleClose} size={'full'} title={event?.type}>
                <Box sx={{width: {xs: '100%'}, margin: '0 auto'}}>
                    {event &&  (<Box sx={{mb:4}}>

                            <Stack direction={"row"} justifyContent={"space-between"} sx={{mb: 2}}>
                                <Typography variant={'h5'} sx={{
                                    color: '#7d7d7d',
                                    fontSize: '0.75rem',
                                    fontWeight: 600
                                }}>{event.sender}</Typography>
                                <Typography variant={'h5'} sx={{
                                    color: '#7d7d7d',
                                    fontSize: '0.75rem',
                                    fontWeight: 600
                                }}>{dayjs(event.createdOn).format('D MMM HH:mm')}</Typography>
                            </Stack>
                            <Typography sx={{width: '100%'}} variant={'h3'}>{event.subject}</Typography>
                            <Box dangerouslySetInnerHTML={{__html: event.event.message}}/>
                     </Box>
                    )}
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1 }}  >
                    {event && event.event.photos.map((ph, index) => {
                        return (
                            <Grid key={index} item xs={6} md={4} lg={3} sx={{height: '200px'}}>
                                <ImagePreview onClick={() => setActiveImageIndex(index)} src={loadedImages ? loadedImages[ph.path] : undefined} sx={{marginTop: '1px'}} width={'100%'} height={'100%'}/>
                            </Grid>
                        )
                    })}
                    </Grid>
                    {event && <ShareImages images={event.event.photos}/>}
                </Box>
            </DialogPage>

            {loadedImages && event && (
                <Lightbox
                    open={activeImageIndex > -1 }
                    close={() => setActiveImageIndex( -1)}
                    index={activeImageIndex}
                    plugins={[Counter, Zoom, Download]}
                    styles={{
                        slide: {"--yarl__carousel_padding_px": '0' }
                    }}
                    counter={{ container: { style: { top: "unset", bottom: 0 } } }}
                    slides={event.event.photos.map((s, i) => {return {"src": loadedImages[s.path], downloadFilename: `image_${i}.jpeg` }} )}
                />
            )}

            <Stack spacing={{xs: 1, sm: 2, md: 4 }} sx={{width: '100%'}} justifyContent={"center"} direction={{ xs: 'column', sm: 'row' }} >
                <Box sx={{
                    width: '100%',
                    [theme.breakpoints.up('lg')]: {
                        minWidth: '1200px',
                        padding: 0,
                    },
                    maxWidth: '1200px'
                }}>
                    {!events && <Loader/>}
                    {events && events.content.length === 0 && <EmptyBox variant={'gallery'} title={t('gallery.empty')}/>}
                    {events && events.content.length > 0 && (
                        <StudentGalleryList events={events} onEventClick={handleOpen} loadMore={loadMore} />
                    )}
                </Box>
            </Stack>
        </Box>
    )
}
