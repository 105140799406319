import {Avatar, Box, Chip, Divider, Stack, Typography} from "@mui/material";
import React from "react";
import dayjs from "dayjs";
import CampaignIcon from "@mui/icons-material/Campaign";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {NotificationBadge} from "../../NotificationBadge";
import {EventMessage, SchoolEvent} from "../../../store/feature/event";

type Props = {
    event: SchoolEvent<EventMessage>
}
function stringToColor(string: string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function stringAvatar(name: string) {
    const bgColour = stringToColor(name);
    const textColor =  getTextColorForBackground(bgColour);
    return {
        sx: {
            bgcolor: stringToColor(name),
            color: textColor
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

function hexToRgb(hex: string) {
    // Remove the "#" if present
    hex = hex.replace("#", "");

    // Parse the r, g, b values from the hex code
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    return { r, g, b };
}

// Function to calculate the relative luminance
function getLuminance(r: number, g: number, b: number) {
    // Normalize the RGB values to the range [0, 1]
    r /= 255;
    g /= 255;
    b /= 255;

    // Apply the gamma correction (sRGB)
    r = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
    g = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
    b = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);

    // Calculate the luminance
    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

// Function to determine whether black or white text will have better contrast
function getTextColorForBackground(hexColor: string) {
    const { r, g, b } = hexToRgb(hexColor);
    const luminance = getLuminance(r, g, b);

    // If luminance is more than 0.5, use black text; otherwise, use white text
    return luminance > 0.5 ? "#393838" : "#FFFFFF";
}


export const AnnounceListView = ({event} : Props) => {

    const theme = useTheme();
    const [month, day, year] = dayjs(event.createdOn).format('MMM D YYYY').split(' ');
    const {t} = useTranslation();

    return (
        <Box>
            <Stack direction={'row'} spacing={3} sx={{mb:2}} alignItems={'center'}>
                <Avatar {...stringAvatar(event.sender)}/>
                <Typography sx={{fontSize: '1.15rem', fontWeight: '500', paddingBottom: '5px', width:'100%', mr:1}}  variant={'h5'}>{event.subject}</Typography>
                <NotificationBadge visible={!event.read}/>
            </Stack>

            <Stack sx={{width: '100%', marginBottom: '7px'}} justifyContent={'space-between'} direction={'row'} alignItems={'center'}>
                <Chip label={t('event.announcement.name')} sx={{textTransform: 'capitalize', color: '#4ba7f9', fontWeight:600, backgroundColor: 'white', border: '1px solid #4ba7f9', '& svg': {color: '#4ba7f9'}}} icon={<CampaignIcon color={'action'}/>}></Chip>

                <Box>
                    <Typography sx={{fontSize: '0.7rem', fontWeight: '600'}}  variant={'body1'}>{event.sender}</Typography>
                    <Typography sx={{fontSize: '0.7rem', fontWeight: '500', color: '#7d8491'}}  variant={'body1'}>{`${t(month)} ${day} ${year}`}</Typography>
                </Box>





            </Stack>

            <Divider sx={{margin: '20px 0'}}/>

            <Typography variant={'body2'} dangerouslySetInnerHTML={{__html: event.event.message.length > 300 ? event.event.message.substring(0, 300) + '... <span style="color:'+theme.palette.primary.main+'">Read More</span>'  : event.event.message}}></Typography>
        </Box>
    )
}
