import {reducer} from "./reducer";
import AjaxService from "../services/Ajax";
import {configureStore} from "@reduxjs/toolkit";

export const store = configureStore({
    reducer,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: {
                extraArgument: {
                    ajax: AjaxService
                },
            }
        }),
})

export type AppDispatch = typeof store.dispatch
export type AppState = ReturnType<typeof store.getState>;
