import AjaxService from "../services/Ajax";
import {API_URL} from "../config";
import {getImageFromDB, saveImageToDB} from "../store/indexdb/imageDB";
import pLimit from "p-limit";

export type UploadedFile = {
   fileName: string
   path: string
}

const downLoadPictureLimit = pLimit(4);

export const loadFile = (filePath: string) => {
   return AjaxService.get(`${API_URL}/api/public/v1/files?file-name=${filePath}`, {headers: {
         "Accept": "image/*,*/*;q=0.8"
      }})
}

const fetchImageInternal = async (src: string): Promise<string> => {
   if(!src) {
      return '/loading2.gif'
   }
   const cachedImage = await getImageFromDB(src);
   if (cachedImage) {
      return URL.createObjectURL(new Blob([cachedImage], { type: 'image/jpeg' }));
   } else {
      try {
         const fileResponse = await loadFile(src);
         if (fileResponse.ok) {
            const fileArrayBuffer = await fileResponse.arrayBuffer();

            // Cache the image in IndexedDB
            await saveImageToDB(src, fileArrayBuffer);
            return URL.createObjectURL(new Blob([fileArrayBuffer], { type: 'image/jpeg' }));
         }

      } catch (error) {
         console.error('Failed to fetch image:', error);
         throw new Error('Failed to fetch an image')
      }
   }

   return '/loading2.gif'
};

const fetchImageWithRetries = (src: string, retries = 2): Promise<string> => {
   return new Promise((resolve, reject) => {
      const attemptDownload = (attempt: number) => {
         fetchImageInternal(src)
             .then((image) => {
                resolve(image);
             })
             .catch((error) => {
                if (attempt <= retries) {
                   console.log(`Retrying load file, attempt ${attempt + 1}`);
                   attemptDownload(attempt + 1);
                } else {
                   resolve('/loading2.gif');
                }
             });
      };
      attemptDownload(0); // Start with the first attempt
   });
};

export const fetchImage =  (src: string): Promise<string> => {
  return downLoadPictureLimit(() => fetchImageWithRetries(src))
};
