import {MainLayout} from "../layout/MainLayout";
import {CalendarPage} from "../pages/CalendarPage";
import {NotificationPage} from "../pages/NotificationPage";
import React from "react";
import {ChatPage} from "../pages/ChatPage";
import {GalleryPage} from "../pages/GalleryPage";

export const MainRoutes = {
    element: <MainLayout/>,
    children: [
        {
            path: '/',
            label: 'calendar',
            element: <CalendarPage/>,
        },
        {
            path: '/example',
            children: [

            ]

        },
        {
            path: '/galleries',
            label: 'galleries',
            element: <GalleryPage/>
        },
        {
            path: '/notifications/announcements',
            label: 'announcements',
            element: <NotificationPage/>
        },
        {
            path: '/notifications/messages',
            label: 'messages',
            element: <ChatPage/>
        }
    ]
}
