import {buildCustomSlice, buildSlice, createGenericAsyncThunk} from "../../../generic";
import {createSelector} from "@reduxjs/toolkit";
import {AppState} from "../../index";
import {API_URL} from "../../../config";
import {getMobileOperatingSystem, isStandaloneMobileApp} from "../../../utils/misc";

export type StudentData = {
    id: number;
    fullName: string;
    avatar: string;
    avatarImage?: string;
    chatRead: boolean;
    galleriesRead: boolean;
    eventsRead: boolean; // NOT USER
};

export type Meal = {
    name: string;
    mealId: number
}

export type StudentPlanChanges = {
    id: number;
    type: 'meal-cancel' | 'absence'
    days: string[];
    mealIds?: number[];
    createdOn: string;
};

export type EmailPreference = {
    invoiceViaEmail: boolean;
    announceViaEmail: boolean;
}


const updateAppIfNeeded = (response: Response) => {
    const existingVersion = localStorage.getItem('compass.version');
    const newVersion = response.headers.get('X-Compass-Version');
    if (newVersion) {
        if (!existingVersion) {
            localStorage.setItem('compass.version', newVersion);
        } else if(existingVersion !== newVersion) {
            if ('serviceWorker' in navigator) {
                caches.keys().then(function(cacheNames) {
                    cacheNames.forEach(function(cacheName) {
                        caches.delete(cacheName).then(r => console.log('Removed App cache ', r));
                    });
                });
            }
            localStorage.setItem('compass.version', newVersion);
            console.log('Updating APP');
            window.location.reload();
        }
    }
}

export const fetchStudentsDetails = createGenericAsyncThunk<StudentData[]>(
    'students/fetchStudentsDetails',
    async (undefined, { extra, dispatch, rejectWithValue }) => {
        try {
            const response = await extra.ajax.get(
                `${API_URL}/api/public/v1/students`
            );
            const data: StudentData[] = await response.json();

            for (const st of data) {
                st.avatarImage = (await import(`../../../assets/images/kids/${st.avatar}`)).default
            }

            if (response.status !== 200) {
                dispatch({'type': '@notification/ADD_NOTIFICATION', value: {id: 'fetchStudentsDetails', variant: 'error', title: 'error.notification.title', text: 'error.notification.application'}});
                return rejectWithValue({statusCode: response.status, response: data});
            }
            try {
                // @ts-ignore
                if (window.navigator && window.navigator.setAppBadge && window.navigator.clearAppBadge) {
                    if (data.filter(a => !a.eventsRead || !a.chatRead).length > 0) {
                        // @ts-ignore
                        window.navigator.setAppBadge(1)
                    } else {
                        // @ts-ignore
                        window.navigator.clearAppBadge();
                    }
                }
            } catch (e) {
                console.log(e);
            }
            updateAppIfNeeded(response); // this is a very first call, and also call which app fetches when app is resumed
            sessionStorage.removeItem('user-students-fetch-retry')
            return data;
        } catch (e) {
            const retry = sessionStorage.getItem('retry')
            if (!retry) {
                sessionStorage.setItem('retry', '1');
                if ('serviceWorker' in navigator) {
                    caches.keys().then(function(cacheNames) {
                        cacheNames.forEach(function(cacheName) {
                            caches.delete(cacheName).then(r => console.log('Removed App cache', r));
                        });
                    });
                }
                window.location.reload()
            }
            throw e;
        }
    }
);

export const fetchStudentChangeRequests = createGenericAsyncThunk<StudentPlanChanges[], { studentId: number }>(
    'students/fetchStudentChangeRequests',
    async ({ studentId }, { extra, dispatch, rejectWithValue }) => {
        const response = await extra.ajax.get(
            `${API_URL}/api/public/v1/students/${studentId}/planned-changes`
        );
        const data: StudentPlanChanges[] = await response.json();

        if (response.status !== 200) {
            dispatch({'type': '@notification/ADD_NOTIFICATION', value: {id: 'fetchStudentChangeRequests', variant: 'error', title: 'error.notification.title', text: 'error.notification.application'}});
            return rejectWithValue({statusCode: response.status, response: data});
        }
        return data;
    }
);

export const addStudentAbsence = createGenericAsyncThunk<object, { days: string[], studentId: number, reason: string }>(
    'students/addStudentAbsence',
    async ({ days, studentId, reason }, { extra, dispatch, rejectWithValue }) => {
        const response = await extra.ajax['post'](`${API_URL}/api/public/v1/students/${studentId}/absence`, {days, type: 'absence', reason});
        const data: object = await response.json();
        if (response.status !== 200) {
            dispatch({'type': '@notification/ADD_NOTIFICATION', value: {id: 'addStudentAbsence', variant: 'error', title: 'error.notification.title', text: 'error.notification.application'}});
            return rejectWithValue({statusCode: response.status, response: data});
        }
        return data;
    }
)

export const removeStudentAbsence = createGenericAsyncThunk<object, { date: string, studentId: number }>(
    'students/removeStudentAbsence',
    async ({ date, studentId }, { extra, dispatch, rejectWithValue }) => {
        const response = await extra.ajax['delete'](`${API_URL}/api/public/v1/students/${studentId}/absence/${date}`);
        const data: object = await response.json();
        if (response.status !== 200) {
            dispatch({'type': '@notification/ADD_NOTIFICATION', value: {id: 'addStudentAbsence', variant: 'error', title: 'error.notification.title', text: 'error.notification.application'}});
            return rejectWithValue({statusCode: response.status, response: data});
        }
        return data;
    }
)

export const addStudentMealCancellation = createGenericAsyncThunk<object, { days: string[], studentId: number, mealIds: number[] }>(
    'students/addStudentMealCancellation',
    async ({ days, studentId, mealIds }, { extra, dispatch, rejectWithValue }) => {
        const response = await extra.ajax['post'](`${API_URL}/api/public/v1/students/${studentId}/meal-cancel`, {days, mealIds, type: 'meal-cancel'});
        const data: object = await response.json();
        if (response.status !== 200) {
            dispatch({'type': '@notification/ADD_NOTIFICATION', value: {id: 'addStudentMealCancellation', variant: 'error', title: 'error.notification.title', text: 'error.notification.application'}});
            return rejectWithValue({statusCode: response.status, response: data});
        }
        return data;
    }
)

export const deleteStudentRequest = createGenericAsyncThunk<object, { planId: number, studentId: number }>(
    'students/addStudentMealCancellation',
    async ({ planId, studentId }, { extra, dispatch, rejectWithValue }) => {
        const response = await extra.ajax['delete'](`${API_URL}/api/public/v1/students/${studentId}/planned-changes/${planId}`);
        const data: object = await response.json();
        if (response.status !== 200) {
            dispatch({'type': '@notification/ADD_NOTIFICATION', value: {id: 'deleteStudentRequest', variant: 'error', title: 'error.notification.title', text: 'error.notification.application'}});
            return rejectWithValue({statusCode: response.status, response: data});
        }
        return data;
    }
)


export const fetchUserMeals = createGenericAsyncThunk<Meal, { studentId: number, language: string }>(
    'user/fetchMeals',
    async ({studentId, language}, {extra, dispatch, rejectWithValue}) => {
        const response = await extra.ajax.get(
            `${API_URL}/api/public/v1/students/${studentId}/meals`,
            {
                headers: {
                    'Accept-Language': language
                }
            }
        );
        const data: Meal = await response.json();

        if (response.status !== 200) {
            dispatch({'type': '@notification/ADD_NOTIFICATION', value: {id: 'fetchUserMeals', variant: 'error', title: 'error.notification.title', text: 'error.notification.application'}});
            return rejectWithValue({statusCode: response.status, response: data});
        }
        return data;
    }
)


export const updateUserToken = createGenericAsyncThunk<void, { token: string }>(
    'user/updateUserToken',
    async ({token}, {extra, dispatch, rejectWithValue}) => {
        const response = await extra.ajax['post'](`${API_URL}/api/public/v1/user/notification-token`, { token, os: getMobileOperatingSystem(), ua: window.navigator.userAgent, pwa: isStandaloneMobileApp()});
        const data = await response.json();
        if (response.status !== 200) {
            dispatch({'type': '@notification/ADD_NOTIFICATION', value: {id: 'updateUserToken', variant: 'error', title: 'error.notification.title', text: 'error.notification.application'}});
            return rejectWithValue({statusCode: response.status, response: data});
        }
    }
)

export const fetUserEmailPreferences = createGenericAsyncThunk<EmailPreference, void>(
    'user/fetUserEmailPreferences',
    async (undefined, {extra, dispatch, rejectWithValue}) => {
        const response = await extra.ajax['get'](`${API_URL}/api/public/v1/user` );
        const data: EmailPreference = await response.json();
        if (response.status !== 200) {
            dispatch({'type': '@notification/ADD_NOTIFICATION', value: {id: 'fetUserEmailPreferences', variant: 'error', title: 'error.notification.title', text: 'error.notification.application'}});
            return rejectWithValue({statusCode: response.status, response: data});
        }
        return data;
    }
)

export const updateUserEmailPreferences = createGenericAsyncThunk<EmailPreference, EmailPreference>(
    'user/updateUserEmailPreferences',
    async (ep, { extra, dispatch, rejectWithValue }) => {
        const response = await extra.ajax['put'](`${API_URL}/api/public/v1/user`, ep);
        const data: EmailPreference = await response.json();
        if (response.status !== 200) {
            dispatch({'type': '@notification/ADD_NOTIFICATION', value: {id: 'updateUserEmailPreferences', variant: 'error', title: 'error.notification.title', text: 'error.notification.application'}});
            return rejectWithValue({statusCode: response.status, response: data});
        }
        return data;
    }
)


const emailPrefSlice = buildCustomSlice<EmailPreference>(
    'emailPrefSlice',
    (builder) => {
        builder.addCase(fetUserEmailPreferences.fulfilled, (state, action) => {
            state.loaded = true;
            state.status = 'succeeded';
            //@ts-ignore
            state.data = action.payload;
            delete state.error;
        });

        builder.addCase(updateUserEmailPreferences.fulfilled, (state, action) => {
            state.loaded = true;
            state.status = 'succeeded';
            //@ts-ignore
            state.data = action.payload;
            delete state.error;
        });
    }
)

export const emailPreferenceReducer = emailPrefSlice.reducer;
export const selectEmailPreference = createSelector([(state: AppState) => state.userEmailPreferences], (emailPref) => emailPref.data);

const studentsFetchSlice = buildSlice<StudentData[], void>(
    'studentsFetchSlice', fetchStudentsDetails.fulfilled
);
export const studentDetailsReducer = studentsFetchSlice.reducer;
export const selectStudentsDetails = createSelector([(state: AppState) => state.students], (student) => student.data);


const studentsFetchChangeRequestsSlice = buildSlice<StudentPlanChanges[], { studentId: number }>(
    'studentsFetchChangeRequestsSlice', fetchStudentChangeRequests.fulfilled
);
export const studentsFetchChangeRequestsReducer = studentsFetchChangeRequestsSlice.reducer;
export const selectStudentChangeRequests = createSelector([(state: AppState) => state.studentChangeRequests], (student) => student.data);
