import {Link} from 'react-router-dom';

// material-ui
import {ButtonBase} from '@mui/material';

// project imports
import {Logo} from '../../../ui-component/Logo';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
  return (
    <ButtonBase disableRipple  component={Link} to={'/'}>
      <Logo />
    </ButtonBase>
  );
};

export default LogoSection;
