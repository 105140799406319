import {Box, Stack, TextField} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import {useTheme} from "@mui/material/styles";
import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {
    SET_MESSAGE_FIELD_DIFF_SIZE,
    SET_TEXT_FIELD_FOCUSED
} from "../../../store/siteEventsReducer";
import {fetchLatestFacilityChatMessages, sendChatMessage} from "../../../store/feature/chat";
import {SxProps} from "@mui/system";
import {IconButtonLoader} from "../../IconButtonLoader";

type Props = {
  position: 'fixed' | 'relative',
  sx?: SxProps
}
export const MessageInput = ({position, sx}: Props) => {
    const theme = useTheme();
    const [draftMessage, setDraftMessage] = useState('');
    const [messageHeight, setMessageHeight] = useState<number | undefined>(undefined);
    const textFieldRef = useRef<HTMLInputElement>(null);

    const [inputFocused, setInputFocused] = useState(false);

    const [rows, setRows] = useState(1);
    const {t} = useTranslation();
    const {activeStudentId, id} = useAppSelector((state) => state.configuration);
    const dispatch = useAppDispatch();
    const [clazz, setClazz] = useState('');
    const isIphone = Boolean(window.navigator && window.navigator.userAgent && window.navigator.userAgent.toLowerCase().includes('iphone'));

    const {appIsActive} = useAppSelector((state) => state.misc)

    useEffect(() => {
        if(!messageHeight && textFieldRef.current) {
            setMessageHeight(textFieldRef.current.scrollHeight)
        }
    }, [textFieldRef.current]);

    useEffect(() => {
        if(!appIsActive) {
            textFieldRef.current?.blur()
        } else if( draftMessage === '') {
            handleFocus(false)
        }

    }, [appIsActive]);

    useEffect(() => {
        const textField = textFieldRef.current;

        if (textField && messageHeight) {
            const rowHeight = parseInt(window.getComputedStyle(textField).lineHeight, 10);
            const newRows = Math.min(Math.floor((textField.scrollHeight - messageHeight + rowHeight) / rowHeight), 5); // 5 is the maxRows

            if (newRows !== rows) {
                setRows(newRows);
                const diff = (newRows - 1) * rowHeight;
                dispatch({type: SET_MESSAGE_FIELD_DIFF_SIZE, value: diff})
            }
        }
    }, [draftMessage, rows]);

    const handleSendMessage = () => {
        textFieldRef.current?.focus()
        return dispatch(sendChatMessage({ studentId: activeStudentId, message: draftMessage})).then(() => {
            dispatch(fetchLatestFacilityChatMessages({studentId: activeStudentId, userId: id}));
        }).then(() => {
        setDraftMessage('');
        dispatch({type: SET_MESSAGE_FIELD_DIFF_SIZE, value: 0})
      })
    }

    const handleFocus  = (isFocused: boolean) => {
        setClazz(isFocused ? 'input--focused' : '');
        setInputFocused(isFocused);
        dispatch({type: SET_TEXT_FIELD_FOCUSED, value: isFocused})
        window.scrollTo(0,0)
    }

    return (
        <>
            <Box component="form" sx={{

                justifyContent: "center",
                width: "100%",
                position: position,
                maxWidth: '900px',

                bottom: {xs:(inputFocused) ? '0px' : (isIphone ? '100px' : '75px'), sm: '0'},
                zIndex: 2,
                backgroundColor: 'white',
                margin: `${theme.spacing(0)} auto`,
                borderTop: `1px solid ${theme.palette.divider}`,
                padding: '20px 7px',
                ...sx
            }}  noValidate autoComplete="off">
                <Stack direction={'row'} sx={{width: '100%'}} spacing={2} alignItems={'end'}>
                    <TextField
                        id="standard-text"
                        inputRef={textFieldRef}
                        fullWidth={true}
                        multiline={true}
                        inputProps={{
                            className: clazz
                        }}
                        placeholder={t("chat.message.placeholder")}
                        maxRows={5}
                        onBlur={() => handleFocus(false)}
                        onFocus={() => handleFocus(true)}

                        sx={{
                            "& fieldset": { border: 'none' },
                            "& textarea": {
                                background: '#ebf5ff',
                                fontSize: '16px',
                                borderRadius: '27px',
                                padding: '10px 14px'
                            },
                            fontSize: '20px',
                            '& .MuiInputBase-root': {
                                background: '#ebf5ff',
                                borderRadius: '27px',
                                border: '1px solid #ddecfb'
                            }
                        }}
                        onChange={(e) => setDraftMessage(e.target.value)}
                        value={draftMessage}
                    />
                    <Stack alignItems={'end'} sx={{transition: 'all 0.5s ease'}} >
                        <IconButtonLoader size={42} onClick={handleSendMessage} disabled={!draftMessage} sx={{transition: 'all 0.5s ease',margin: '2px', backgroundColor: theme.palette.primary.main}}>
                            <SendIcon sx={{width: '20px', height: '20px'}}/>
                        </IconButtonLoader>
                    </Stack>
                </Stack>
            </Box>
        </>
    )
}
