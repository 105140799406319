import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {AppDispatch} from "../../store";
import {useAppSelector} from "../index";
import {fetchLatestStudentEvents, selectStudentEvents} from "../../store/feature/event";

export const useFetchStudentEvents = () => {
    const dispatch = useDispatch<AppDispatch>();
    const {activeStudentId, language} = useAppSelector((state) => state.configuration);

    useEffect(() => {
        if (activeStudentId !== -1) {
            dispatch(fetchLatestStudentEvents({studentId: activeStudentId, language}));
        }

    }, [dispatch, activeStudentId, language]);

    return useAppSelector((state) => selectStudentEvents(state));
}
