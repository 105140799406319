import {Box, Typography} from "@mui/material";
import React from "react";
import EmptyBoxImage from '../../assets/images/empty.png';
import EmptyPlanet from '../../assets/images/empty-planet.png';
import {IconPhotoSearch} from "@tabler/icons-react";
type Props = {
    variant: 'events' | 'messages' | 'gallery'
    title: string
    padding?: string
}
export const EmptyBox = ({variant, title, padding = '100px 20px'}: Props) => {
    return (<Box sx={{width: '100%', textAlign: 'center', padding}}>
        {variant === 'gallery' && <IconPhotoSearch size={'10.3rem'} style={{opacity: '0.6'}} stroke={1} color={'#7d8491'} />}
        {variant === 'messages' && <img src={EmptyBoxImage} style={{filter: 'grayscale(100%)', opacity: 0.3}} alt=""/> }
        {variant === 'events' && <img src={EmptyPlanet} style={{width: '90%'}} alt=""/> }
        <Typography variant={'h3'} sx={{color: '#7d8491', lineHeight: '1.5', opacity: '0.6', mt: '20px'}}>{title}</Typography>
    </Box>)
}
