import {CalendarEvent} from "../store/feature/calendar";

export const EVENT_TYPES = {
    "holiday": {
        extraLight: '#b6b6b61c',
        lightColor: "#b6b6b6f5",
        darkColor: "#787878",
        textColor: "#f5f5f5",
        name: "eventType.holiday"
    },
    "absence": {
        extraLight: '#FFF3F3AD',
        lightColor: "#ff6d6dc2",
        darkColor: "#ff5656",
        textColor: "#ffffff",
        name: "eventType.absence"
    },
    "day-care": {
        extraLight: 'rgba(255,239,211,0.75)',
        lightColor: "#ffb440e8",
        darkColor: "#ff9b00",
        textColor: "#ffffff",
        name: "eventType.day-care"
    },
    "cancelled-meals": {
        extraLight: '#4356ff0d',
        lightColor: "#4356ff7d",
        darkColor: "#374af9",
        textColor: "#ffffff",
        name: "eventType.cancelled-meals"
    },
    "extra-class": {
        extraLight: '#14a3ae12',
        lightColor: "#14a3ae",
        darkColor: "#017780",
        textColor: "#ffffff",
        name: "eventType.extra-class"
    },
    "custom": {
        extraLight: '#ffce4314',
        lightColor: "#ffce437d",
        darkColor: "#ffce43",
        textColor: "#000000",
        name: "eventType.custom"
    }
}

export const calendarEventTypes = [EVENT_TYPES.absence, EVENT_TYPES.holiday, EVENT_TYPES["day-care"], EVENT_TYPES["cancelled-meals"], EVENT_TYPES["extra-class"], EVENT_TYPES.custom]


export const getCalendarEventStyles = (events: CalendarEvent[], isToday: boolean, isDaySelected: boolean) => {
    let eventType = events[0].eventType;

    if (events.length > 1) {
        const holidays = events.filter(a => a.eventType === 'holiday');
        const absences = events.filter(a => a.eventType === 'absence');
        const customs = events.filter(a => a.eventType === 'custom');

        if (holidays.length > 0) {
            eventType = 'holiday';
        } else if(absences.length > 0) {
            eventType = 'absence';
        } else if(customs.length > 0) {
            eventType = 'custom';
        }
    }


    return {
        '&.Mui-selected': {
            backgroundColor: isDaySelected ? EVENT_TYPES[eventType].darkColor :EVENT_TYPES[eventType].lightColor,
            color: EVENT_TYPES[eventType].textColor,
            border: isToday ? '2px solid #697586' : events.length > 1 ? '2px solid rgb(73, 73, 73)' : 'none'
        },
        '&.Mui-selected:hover': {
            backgroundColor: EVENT_TYPES[eventType].darkColor,
        },
        '&:focus.Mui-selected': {
            backgroundColor: isDaySelected ? EVENT_TYPES[eventType].darkColor :EVENT_TYPES[eventType].lightColor,
        }
    }
}

