import {List, ListItem, ListItemButton, Stack} from "@mui/material";
import React from "react";
import {PageableList} from "../../../types";
import {useInfiniteScroll} from "../../../hooks/useInfiniteScroll";
import {AnnounceListView} from "./AnnounceListView";
import {InvoiceListView} from "./InvoiceListView";
import {useOutletContext} from "react-router";
import {ScrollLoader} from "../../ScrollLoader";
import {EventInvoice, EventMessage, SchoolEvent} from "../../../store/feature/event";

type Props = {
    events: PageableList<SchoolEvent<EventMessage | EventInvoice>>
    onEventClick: (event: SchoolEvent<EventMessage | EventInvoice>) => void
    loadMore: (page: number) => Promise<any>
}

export const StudentEventsList = ({events, onEventClick, loadMore}: Props) => {

    // @ts-ignore
    const {mainRef} = useOutletContext();


     const { loading } = useInfiniteScroll<HTMLUListElement>({
        fetchMoreData: loadMore,
        totalPage:  events.totalPages - 1,
        offsetContainerHeight: 100,
        direction: 'down',
        containerRef: mainRef
    });

    return (
        <>
        <List sx={{ width: '100%' }}  >
            {events && events.content.length > 0 && events.content.map((event, index) => (
                <ListItem key={index} alignItems="flex-start" sx={{backgroundColor: 'white', marginBottom:'20px', borderRadius: '10px', padding: 0, boxShadow: '0 8px 16px #0000000d'}}>
                    <ListItemButton sx={{padding: '15px 25px'}} onClick={() => onEventClick(event)}>
                        <Stack direction={'column'} sx={{ width: '100%'}}>
                            {event.type === 'announcement' && <AnnounceListView event={event as SchoolEvent<EventMessage>}/>}
                            {event.type === 'invoice' && <InvoiceListView event={event as SchoolEvent<EventInvoice>}/>}
                        </Stack>
                    </ListItemButton>
                </ListItem>
            ))}

        </List>
            <ScrollLoader show={loading} direction={'down'}/>
        </>
    )
}
