import React, {useEffect, useState} from 'react';
import './App.css';
import {CssBaseline, StyledEngineProvider, ThemeProvider} from "@mui/material";
import themes from "./themes";
import {Routes} from "./routes";
import {Amplify} from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import {Authenticator} from "@aws-amplify/ui-react";
import {SET_APP_USER,} from "./store/configurationReducer";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isToday from 'dayjs/plugin/isToday';
import updateLocale from 'dayjs/plugin/updateLocale';
import {useTranslation} from "react-i18next";
import {initMessaging} from "./firebase";
import {SignInHeader} from "./ui-component/widget/login/SignInHeader";
import {Loader} from "./ui-component/Loader";
import {fetchStudentsDetails} from "./store/feature/user";
import {useAppDispatch} from "./hooks";
import 'dayjs/locale/en';
import 'dayjs/locale/pl';
import {initPWACache} from "./pwa";
import {fetchUserDetails} from "./utils/auth";
import {SET_MAYBE_ACTIVE_VIRTUAL_KEYBOARDS} from "./store/siteEventsReducer";
import {SET_APP_VISIBLE} from "./store/miscReducer";

function App() {

    const dispatch = useAppDispatch();

    const {i18n} = useTranslation();

    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

    Amplify.configure({
        Auth: {
            Cognito: {
                userPoolClientId: '66mth4h956gf04ku5gap3rbfoj',
                userPoolId: 'eu-north-1_F29SLEncR',
            }
        }
    });

    dayjs.extend(utc);
    dayjs.extend(updateLocale);
    dayjs.extend(timezone);
    dayjs.extend(isToday);
    dayjs.updateLocale('en', {
        weekStart: 1
    });

    const fetchUser = () => {
        fetchUserDetails().then((user)=> {
            if(user) {
                dayjs.locale(user.language);
                setIsUserLoggedIn(true);
                dispatch({type: SET_APP_USER, value: user});
                return i18n.changeLanguage(user.language.toLowerCase());
            }
        })
    }

    useEffect(() => {
        if(isUserLoggedIn) {
            initMessaging(dispatch, (payload) => {
                dispatch(fetchStudentsDetails());
            });
            initPWACache(dispatch);
            window.addEventListener("visibilitychange", function () {
                dispatch({type: SET_APP_VISIBLE, value: document.visibilityState === "visible"})
                if (document.visibilityState === "visible") {
                    console.log("APP resumed");
                    dispatch(fetchStudentsDetails());
                }
            });

            //track virtual keyboard
            if ('visualViewport' in window) {
                const VIEWPORT_VS_CLIENT_HEIGHT_RATIO = 0.75;
                // @ts-ignore
                window.visualViewport.addEventListener('resize', function (event) {
                    // @ts-ignore
                    if ((event.target.height * event.target.scale) / window.screen.height < VIEWPORT_VS_CLIENT_HEIGHT_RATIO) {
                        dispatch({type: SET_MAYBE_ACTIVE_VIRTUAL_KEYBOARDS, value: true})
                    } else {
                        dispatch({type: SET_MAYBE_ACTIVE_VIRTUAL_KEYBOARDS, value: false})
                    }
                });
            }
        }

    }, [isUserLoggedIn]);
    return (
        <Authenticator hideSignUp={true} components={{ SignIn: {Header: SignInHeader}}} >
            {({ signOut, user }) => {

                if (isUserLoggedIn) {
                    return (
                        <StyledEngineProvider injectFirst>
                            <ThemeProvider theme={themes()}>
                                <CssBaseline/>
                                <Routes/>
                            </ThemeProvider>
                        </StyledEngineProvider>
                    )
                } else {
                    fetchUser();
                    return <Loader/>
                }
            }}
        </Authenticator>
  );
}

export default App;


