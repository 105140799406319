import React, {useEffect} from "react";
import {SxProps} from "@mui/system";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckIcon from '@mui/icons-material/Check';
import CircularProgress from '@mui/material/CircularProgress';
import {IconButton} from "@mui/material";
import {useTheme} from "@mui/material/styles";

type Props = {
    sx?: SxProps,
    size: number,
    onClick: () => Promise<any>
    disabled?: boolean
    children: React.ReactElement
}

export const IconButtonLoader = ({sx, onClick, disabled = false, children, size}: Props) => {

    const theme = useTheme();

    const [loading, setLoading] = React.useState(false);
    const [status, setStatus] = React.useState<'none' | 'success' | 'error'>('none');
    const [disabledButton, setDisableButton] = React.useState(disabled);
    const timer = React.useRef<ReturnType<typeof setTimeout>>();

    useEffect(() => {
        return () => {
            clearTimeout(timer.current);
        };
    }, []);
    useEffect(() => {
        setDisableButton(disabled)
    }, [disabled]);
    const handleButtonClick = () => {
        if (!loading) {
            setLoading(true);
            setDisableButton(true)
            onClick()
                .then(() => {
                    setStatus('success');
                })
                .catch(()=> {
                    setStatus('error');
                })
                .finally(() => {
                    setLoading(false);
                    setDisableButton(false);
                    timer.current = setTimeout(() => {
                        setStatus('none');
                    }, 2000);
                })
        }
    };

    return (
        <>
            <IconButton onMouseDownCapture={handleButtonClick} disabled={disabledButton} sx={sx}>
                {status === 'success' && <CheckIcon color={"success"}/>}
                {status === 'error' && <ErrorOutlineIcon color={"error"}/>}
                {status === 'none' && children}
            </IconButton>
            {loading && (<CircularProgress
                size={size}
                sx={{
                    color: theme.palette.success.main,
                    position: 'absolute',
                    right: '6px'
                }}
            />)}
        </>
    )
}
