import React, {useState} from "react";
import {Image} from "../../store/feature/event";
import {getImageFromDB} from "../../store/indexdb/imageDB";
import {Box} from "@mui/material";
import {isStandaloneMobileApp} from "../../utils/misc";
import {LoadingButton} from "@mui/lab";

type Props = {
    images: Image[],
    title?: string,
    text?: string
}

export const ShareImages = ({images, title, text}: Props) => {

    // @ts-ignore
    const shareApiAvailable = navigator.share && isStandaloneMobileApp();

    const [loading, setLoading] = useState(false);

    const shareImages = async () => {
        setLoading(true);
            try {
                // Convert the image URLs to File objects
                const files = await Promise.all(
                    images.map(async (image, index) => {
                        const file = await getImageFromDB(image.path);
                        if (file) {
                            const blob = new Blob([file], { type: 'image/jpeg' }) // Specify MIME type if needed
                            return new File([blob], `image${index + 1}.jpg`, { type: blob.type });
                        }
                    }).filter(v => v !== undefined)
                );

                if (files.length > 0) {
                    await navigator.share({
                        title,
                        text,
                        files: files as File[],
                    });
                    console.log("Share successful!");
                }
            } catch (error) {
                console.error("Error sharing files:", error);
            } finally {
                setLoading(false)
            }
    };

    return (
        <Box sx={{textAlign:'center', padding: '30px'}}>
            {shareApiAvailable && (<LoadingButton loading={loading} disabled={!shareApiAvailable} sx={{fontSize: '1.0rem'}} onClick={shareImages}>Save all images</LoadingButton>)}
        </Box>
    );
};

export default ShareImages;
