import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import en from './locales/en.json';
import pl from './locales/pl.json';

i18n
    .use(initReactI18next)
    .init({
        lng: 'en',
        fallbackLng: 'en',
        keySeparator: '',
        interpolation: {
            escapeValue: false,
        },
        resources: {
            en: {
                translation: en,
            },
            pl: {
                translation: pl,
            },
        },
        react: {
            transKeepBasicHtmlNodesFor: ['b', 'i', 'u', 'br', 'p'],
        },
    })
    .then();
