import {Box, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel} from '@mui/material';
import {Controller, UseFormReturn} from 'react-hook-form';
import * as React from 'react';
import {useEffect, useState} from "react";

type TextFieldProps = {
  name: string;
  label: string;
  checkedByDefault?: boolean
  options: { label: string; value: unknown }[];
  inverseValues?: boolean
  defaultChecked?: boolean
  form: UseFormReturn;
};
export const CheckBoxGroupField = ({ name, inverseValues, form, options, checkedByDefault = false }: TextFieldProps) => {
  const predefined = checkedByDefault ? options.map(o => o.value) : [];

  console.log(predefined)
  const [selectedItems, setSelectedItems] = useState<any>(predefined);

  useEffect(() => {
    if (inverseValues) {
      form.setValue(name, options.filter((option) => !selectedItems.includes(option.value)).map((option) => option.value), { shouldDirty: true });
    } else {
      form.setValue(name, selectedItems, { shouldDirty: true });
    }

  }, [name, selectedItems, form, inverseValues]);

  const handleSelect = (value: any) => {
    if (selectedItems.indexOf(value) === -1) {
      setSelectedItems((prevItems: any) => [...prevItems, value]);
    } else {
      const remaining = selectedItems.filter((item: any) => item !== value);
      setSelectedItems(remaining);
    }
  };




  return (
    <Box sx={{ padding: '10px 0' }}>
      <FormControl>
        <FormGroup >
          {options.map((option, index) => (
            <FormControlLabel key={index} control={
                <Controller
                    name={name}
                    control={form.control}
                    render={({ field }) => <Checkbox
                        checked={selectedItems.includes(option.value)}
                        onChange={() => handleSelect(option.value)}
                    />}
                    key={index}
                />
            } label={option.label} />
          ))}
        </FormGroup>
      </FormControl>
    </Box>
  );
};
