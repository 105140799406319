import {ToggleButton, ToggleButtonGroup} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import {SET_LANGUAGE} from "../../../../store/configurationReducer";
import {useTranslation} from "react-i18next";
import { ReactComponent as PlFlag } from "../../../../assets/images/icons/pl.svg";
import { ReactComponent as EnFlag } from "../../../../assets/images/icons/en.svg";
import {handleUpdateUserAttribute} from "../../../../utils/auth";
import dayjs from "dayjs";

export const LanguageSwitcher = () => {

    const dispatch = useAppDispatch();
    const { i18n } = useTranslation();
    const {language} = useAppSelector(state => state.configuration);

    const handleChange = async (
        event: React.MouseEvent<HTMLElement>,
        newLanguage: string,
    ) => {
        if (newLanguage) {
            handleUpdateUserAttribute('locale', newLanguage === 'EN' ? 'en' : 'pl').then( async () => {
                dispatch({type: SET_LANGUAGE, value: newLanguage});
                await i18n.changeLanguage(newLanguage.toLowerCase());
                dayjs.locale(newLanguage.toLowerCase());
                window.location.reload();
            })
        }
    };

    return (
        <ToggleButtonGroup
            color="primary"
            value={language}
            sx={{backgroundColor: '#14a3ae29', '& .Mui-selected': {backgroundColor: '#14a3ae82!important'}}}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
        >
            <ToggleButton sx={{padding: {xs: '10px 19px', sm: '10px 50px'}}} value="EN"><EnFlag/></ToggleButton>
            <ToggleButton sx={{padding: {xs: '10px 19px', sm: '10px 50px'}}} value="PL" ><PlFlag/></ToggleButton>
        </ToggleButtonGroup>
    );
}
