import {Box, Paper, Stack, Typography} from "@mui/material";
import {MessageLeft, MessageRight} from "./Message";
import React, {useEffect, useRef, useState} from "react";
import {ChatItem, ChatItemDate, ChatItemMessage, PageableList} from "../../../types";
import {useInfiniteScroll} from "../../../hooks/useInfiniteScroll";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../hooks";
import {useOutletContext} from "react-router";
import {ScrollLoader} from "../../ScrollLoader";

type ChatWindowProps = {
  pageableItems: PageableList<ChatItem>
  loadMore: (page: number) => Promise<any>
}

export const ChatWindow = ({pageableItems, loadMore}: ChatWindowProps) => {
    const bottomOfMessagesRef = useRef(null);
    const {t} = useTranslation();

    // @ts-ignore
    const {mainRef} = useOutletContext();

    const { loading, reset } = useInfiniteScroll<HTMLDivElement>({
        fetchMoreData: loadMore,
        containerRef: mainRef,
        totalPage:  pageableItems.totalPages - 1,
        offsetContainerHeight: 0,
        direction: 'up',
    });

    const {virtualKeyboardIsMaybeVisible, textFieldIsFocused} = useAppSelector(state => state.siteEvents)
    const [withVirtualKeyboard, setWithVirtualKeyboard] = useState(false)

    useEffect(() => {
        if(virtualKeyboardIsMaybeVisible && textFieldIsFocused) {
            setWithVirtualKeyboard(true);
        } else {
            setWithVirtualKeyboard(false)
        }

    }, [virtualKeyboardIsMaybeVisible, textFieldIsFocused]);

    useEffect(() => {
        if (pageableItems.first) {
            // @ts-ignore
            bottomOfMessagesRef?.current?.scrollIntoView();
            reset()
        }
    }, [pageableItems]);

  return (
    <Paper sx={{
      display: "flex",
      backgroundColor:'inherit',
      alignItems: "center",
      flexDirection: "column",
      position: "relative",
      width: "100%"
    }}>
        <Box sx={{width: "100%"}}>

            <Paper id="style-1"  sx={{
                margin: '0 10px',
                backgroundColor:'inherit',
                padding:'10px',
                paddingBottom: withVirtualKeyboard ? '0' : '120px',
                '& p': {
                    fontSize: '0.95rem'
                }
            }}>

                {(!pageableItems || pageableItems.empty)  && (
                    <>
                        <Typography variant={'body1'} sx={{fontSize: '2rem', color: 'grey', mt: '50px', opacity: '0.5'}}>{t('chat.empty.title')}</Typography>
                        <Typography variant={'body1'} sx={{fontSize: '1.5rem', color: 'grey', mt: '30px', opacity: '0.5'}}>{t('chat.empty.description')}</Typography>
                    </>
                )}

                {pageableItems && !pageableItems.empty && (
                    <ScrollLoader direction={'up'} show={loading}/>
                )}


                {pageableItems && pageableItems.content.map((item, index) => {
                    if (item.type === 'date') {
                        return (
                            <Stack textAlign={'center'} key={index}>
                                <Typography sx={{padding: "10px"}}>{t((item as ChatItemDate).month) + ' ' + (item as ChatItemDate).day }</Typography>
                            </Stack>
                        )
                    } else if (item.type === 'message') {
                        const messageItem = item as ChatItemMessage;
                        if (messageItem.userMessage) {
                            return (<MessageRight
                                key={index}
                                message={messageItem.message}
                                timestamp={messageItem.time}
                                displayName={messageItem.author}
                            />)
                        } else {
                            return (<MessageLeft
                                key={index}
                                message={messageItem.message}
                                timestamp={messageItem.time}
                                displayName={messageItem.author}
                            />)
                        }
                    }
                })}
                <div ref={bottomOfMessagesRef}></div>
            </Paper>
        </Box>
    </Paper>
  )
}
