import User1 from "../../../../assets/images/users/user-round.svg";
import {Avatar} from "@mui/material";
import React from "react";
import {useTheme} from "@mui/material/styles";
import {StudentData} from "../../../../store/feature/user";

type AvatarProps = {
    student: StudentData
    anchorRef: any
    active: boolean
}
export const StudentAvatar = ({student, anchorRef, active}: AvatarProps) => {

    const theme = useTheme();
    const styles = {
        //@ts-ignore
        ...theme.typography.mediumAvatar,
        margin: '8px 0 8px 8px !important',
        cursor: 'pointer'
    }
    if (active) {
        styles['border'] = `2px solid ${theme.palette.secondary.main}`
        styles['width'] = '38px';
        styles['height'] = '38px';
    } else {
        styles['width'] = '30px';
        styles['height'] = '30px';
        styles['filter']= 'grayscale(0.9)';
    }
    return (
        <Avatar
            src={student.avatarImage || User1}
            sx={styles}
            ref={anchorRef}
            aria-haspopup="true"
            color="inherit"
        />
    )
}
