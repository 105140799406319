import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DateCalendar, LocalizationProvider, PickersDay} from "@mui/x-date-pickers";
import React, {useEffect} from "react";
import {List, ListItem, Stack, Tooltip, Typography} from "@mui/material";
import dayjs, {Dayjs} from "dayjs";
import {CalendarEvent} from "../../../store/feature/calendar";
import {PickersDayProps} from "@mui/x-date-pickers/PickersDay/PickersDay";
import {EVENT_TYPES, getCalendarEventStyles} from "../../../themes/eventTypes";
import {useTranslation} from "react-i18next";
import {useSearchParams} from "react-router-dom";
import {useAppSelector} from "../../../hooks";
import {CustomCalendarHeader} from "./CustomCalendarHeader";
import {getYearMonth, getYearMonthDayJsDate} from "../../../utils/misc";

type DayProps = PickersDayProps<Dayjs> &  {
    events: CalendarEvent[],
    day: Dayjs,
    focusDay: (event: React.FocusEvent<HTMLButtonElement>, day: Dayjs) => void
    selectedDay: Dayjs | string

}

const CalendarEventDay = ({events, day, focusDay, onDaySelect, outsideCurrentMonth, isFirstVisibleCell, isLastVisibleCell, selectedDay}: DayProps) => {
    const {t} = useTranslation();
    const calendarEvents = events.filter(c => !c.cancelled)

    const titles = calendarEvents.map((d) => {

        let title = d.info;
        if (d.start) {
            title += ` - ${d.start}`
        }
        if (d.end) {
            title += ` - ${d.end}`
        }
        return {
            title,
            eventType: d.eventType
        };
    });

    const dayEventsDescription = (
        <Stack>
            <List>
                { titles.map((title, index) =>
                    (<ListItem key={index} sx={{padding: 1}}>
                        <Typography sx={{color: '#ffffff', fontSize: 16}} variant={"body2"} >
                            {title.eventType === 'custom' ? title.title : title.title ? `${t(EVENT_TYPES[title.eventType].name)}: ${title.title}` : t(EVENT_TYPES[title.eventType].name)}
                        </Typography>
                    </ListItem>)
                )}
            </List>
        </Stack>
    )

    if (calendarEvents.length === 0) {
        return <PickersDay
            onDaySelect={onDaySelect}
            autoFocus={day.isToday()}
            onFocus={(e) => focusDay(e, day)}
            disabled={day.day() === 0 || day.day() === 6}
            isFirstVisibleCell={isFirstVisibleCell}
            isLastVisibleCell={isLastVisibleCell}
            outsideCurrentMonth={outsideCurrentMonth}
            day={day}
            today={day.isToday()}
            selected={false}
        />

    } else {
        const isDaySelected = typeof selectedDay === 'string' ? false : day.format('YYYYMD') === selectedDay.format('YYYYMD')
        return (
            <Tooltip title={dayEventsDescription}>
                <PickersDay sx={getCalendarEventStyles(calendarEvents, day.isToday(), isDaySelected)} onFocus={(e) => focusDay(e, day)} onDaySelect={onDaySelect} isFirstVisibleCell={isFirstVisibleCell} isLastVisibleCell={isLastVisibleCell} outsideCurrentMonth={outsideCurrentMonth} day={day} selected={true}  />
            </Tooltip>
        )
    }
}

type CalendarDayProps = PickersDayProps<Dayjs> & {
    calendarEvents: CalendarEvent[],
    selectedDate: Dayjs | string
}
// @ts-ignore
const ServerDay = (props : CalendarDayProps) => {
    const {  day, onDaySelect, outsideCurrentMonth, isFirstVisibleCell, isLastVisibleCell, calendarEvents = [],  selectedDate } = props;
    const dayStr = day.format('YYYY-MM-DD');
    const dayCalendarEvents = calendarEvents.filter((d: CalendarEvent) => d.date === dayStr) as CalendarEvent[];


    return (<CalendarEventDay
        day={day}
        selectedDay={selectedDate}
        onDaySelect={onDaySelect}
        outsideCurrentMonth={outsideCurrentMonth}
        isFirstVisibleCell={isFirstVisibleCell}
        isLastVisibleCell={isLastVisibleCell}
        events={dayCalendarEvents}
        focusDay={(e) => {}}
    />)
};

type CalendarProps = {
    calendarEvents: CalendarEvent[]
    selectedDate: Dayjs | string
    setSelectedDate: (day: Dayjs | string) => void
}

export const StudentDateCalendar = ({calendarEvents, selectedDate, setSelectedDate}: CalendarProps) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const {language} = useAppSelector((state) => state.configuration);

    useEffect(() => {
        if(!searchParams.get("date")) {
            searchParams.set('date', dayjs().format('YYYY-MM-DD'));
            setSearchParams(searchParams)
        }
    }, []);

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language}>
            <DateCalendar
                timezone={'UTC'}
                views={[ 'day']}
                referenceDate={getYearMonthDayJsDate(selectedDate)}
                value={typeof selectedDate == 'string' ? null : getYearMonthDayJsDate(selectedDate)}
                sx={{
                    '&': {
                        maxHeight: '344px',
                        height: '344px',
                    },
                    '& .MuiDayCalendar-weekDayLabel': {
                        fontSize: '1em',
                        width: '39px'
                    },
                    '& .MuiPickersCalendarHeader-label': {
                        textTransform: 'capitalize'
                    },
                    '& .MuiPickersDay-root': {
                        fontSize: '1em',
                        width: '39px',
                        height: '39px'
                    },
                    '& .MuiPickersSlideTransition-root': {
                        minHeight: '250px'
                    },
                    '& .MuiTypography-body2': {
                        textTransform: 'capitalize',
                        fontSize: '1rem'
                    }
                }}
                onMonthChange={(v: Dayjs) => {
                    setSelectedDate(getYearMonth(v));
                    searchParams.delete('date');
                    setSearchParams(searchParams);
                }}
                onChange={(day: Dayjs) => {
                    if (searchParams.get("date") === day.format('YYYY-MM-DD')) {
                        searchParams.delete('date');
                        setSelectedDate(getYearMonth(day));
                    } else {
                        searchParams.set('date', day.format('YYYY-MM-DD'));
                        setSelectedDate(day);
                    }
                    setSearchParams(searchParams);
                }}
                slots={{
                    day: ServerDay as any,
                    calendarHeader: CustomCalendarHeader
                }}
                slotProps={{
                    day: {
                        //@ts-ignore
                        calendarEvents,
                        selectedDate
                    }
                }}
            />
        </LocalizationProvider>
    );
}
