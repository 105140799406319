import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {fetchStudentsDetails, selectStudentsDetails} from "../../store/feature/user";
import {AppDispatch} from "../../store";
import {useAppSelector} from "../index";

export const useFetchStudentsDetails = () => {
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(fetchStudentsDetails());
    }, [dispatch]);

    return useAppSelector((state) => selectStudentsDetails(state));
}
