import React, { ErrorInfo } from 'react';
import { Box, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import { API_URL } from '../../../config';
import { fetchAuthSession } from 'aws-amplify/auth';

export type ErrorBoundaryProps = {
  children: React.ReactNode;
};

export type ErrorBoundaryState = {
  hasError: boolean;
};

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return {
      hasError: true,
    };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error);
    return fetchAuthSession().then((session) => {
      window.fetch(`${API_URL}/api/v1/logger/error`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorisation': session?.tokens?.idToken?.toString() ?? ''
        },
        body: JSON.stringify({
          error: error?.toString(),
          url: document.location.pathname,
          stack: errorInfo.componentStack,
          width: window.innerWidth
        }),
      }).catch(({ message }) => console.error(message));
    })
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <Box sx={{padding: '20px', textAlign: 'center'}}>
        <Typography variant={'h3'} dangerouslySetInnerHTML={{__html: "Oops.. &#128531; <br/><br/>Something went wrong. "}}/>

        <Typography variant={'body2'} sx={{margin: '20px 0'}}>Press a button to reload the app</Typography>
        <Button variant={'contained'} type={"button"} onClick={() => { document.location.pathname = '/' }}>Reload</Button>
      </Box>;
    }

    return <>{children}</>;
  }
}

export default ErrorBoundary;
