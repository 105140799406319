import {useState, useEffect, useCallback, MutableRefObject} from 'react';

export interface UseInfiniteScrollProps {
  fetchMoreData: (page: number) => Promise<any>;
  totalPage: number;
  initialPage?: number;
  offsetContainerHeight?: number;
  containerRef: MutableRefObject<HTMLDivElement | undefined>;
  direction: 'up' | 'down';
}

export function useInfiniteScroll<T extends HTMLElement>({
  fetchMoreData,
  totalPage,
  direction = 'down',
  initialPage = 0,
  offsetContainerHeight = 0,
  containerRef,
}: UseInfiniteScrollProps) {
  const [page, setPage] = useState<number>(initialPage);
  const [loading, setLoading] = useState<boolean>(false);

  const scrollPageHandler = useCallback(() => {
    if (!containerRef.current) return;
    const { scrollHeight, offsetHeight, scrollTop } = containerRef.current;
    if (direction === 'down') {
      const scrollBottomPosition = scrollHeight - offsetHeight - offsetContainerHeight;
      if (scrollTop > scrollBottomPosition && !loading) {
        setLoading(true);
      }
    } else {

      const scrollTopPosition = containerRef.current.scrollTop;
      if (scrollTopPosition <= offsetContainerHeight && !loading) {
        setLoading(true);
      }
    }
  }, [loading, offsetContainerHeight]);

  const loadMore = () => {
    const nextPage = page + 1;
    let distanceToBottom = 0;
    setPage(nextPage);
    if (containerRef.current) {
        containerRef.current?.removeEventListener('scroll', scrollPageHandler);
        distanceToBottom = containerRef.current?.scrollHeight - containerRef.current.scrollTop - containerRef.current.offsetHeight;
    }
    setTimeout(() => {
      if (page === totalPage) {
        setLoading(false);
      } else {
        fetchMoreData(nextPage).finally(() => {
          if ( containerRef.current && direction === 'up') {
            containerRef.current.scrollTo({top: containerRef.current.scrollHeight - containerRef.current.offsetHeight - distanceToBottom})
          }
          setLoading(false);
        });
      }

    }, direction === 'up' ? 1000 : 100);


  };

  useEffect(() => {

    if (page === totalPage || loading) return;
      const ref = containerRef.current;
      if(!ref) return;
      ref.addEventListener('scroll', scrollPageHandler);
      return () => ref.removeEventListener('scroll', scrollPageHandler);
  }, [page, totalPage, scrollPageHandler, loading]);

  useEffect(() => {
    if (!loading) return;
    loadMore();
    // eslint-disable-next-line
  }, [loading]);

  return { containerRef, loading, reset: () => setPage(0) };
}
