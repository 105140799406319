import {Box, Divider, Stack, Typography} from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import {useAppSelector} from "../../../hooks";
import {useTranslation} from "react-i18next";
import {EventInvoice, SchoolEvent} from "../../../store/feature/event";

type Props = {
    event: SchoolEvent<EventInvoice>
}
export const InvoiceCard = ({event}: Props ) => {
    const { language } = useAppSelector((state) => state.configuration)
    const date = dayjs(`${event.event.year}-${event.event.month}-01`).format('MMMM YYYY');
    const [month, year] = date.split(' ');
    const {t} = useTranslation();
    return (
        <Box sx={{width: {xs: '100%', md: '500px'}, margin: '0 auto'}}>
            <Box sx={{padding: '20px 0'}}>
                <Typography variant={'body1'}
                            sx={{fontSize: '0.7rem', color: '#8f8f8f', fontWeight: '600'}}>Issuer:</Typography>
                <span>Edu Fest Spółka z o.o.</span>
            </Box>
            <Box sx={{paddingBottom: '20px'}}>
                <Typography variant={'body1'}
                            sx={{fontSize: '0.7rem', color: '#8f8f8f', fontWeight: '600'}}>Account:</Typography>
                <span>59105014451000009083097122</span>
            </Box>
            <Box>
                <Typography variant={'body1'} sx={{
                    fontSize: '0.7rem',
                    color: '#8f8f8f',
                    fontWeight: '600',
                    marginBottom: '-2px'
                }}>Description:</Typography>
                <Typography variant={'body2'} sx={{
                    fontSize: '1.1rem',
                    fontWeight: '500',
                    letterSpacing: '1px'
                }}>{'Monthly invoice: '} {t(month) + ' ' + year}</Typography>
            </Box>
            <Divider sx={{margin: '10px 0'}}/>
            <Box>


                <Stack direction={'row'} justifyContent={'space-between'} sx={{marginBottom: '10px'}}>
                    <Box>
                        <Box sx={{marginBottom: '10px'}}>
                            <Typography variant={'body1'} sx={{
                                fontSize: '0.7rem',
                                color: '#8f8f8f',
                                fontWeight: '600',
                                marginBottom: '-2px'
                            }}>Date of issue</Typography>
                            <Typography variant={'body2'} sx={{
                                fontSize: '1.1rem',
                                fontWeight: '700',
                                letterSpacing: '1px'
                            }}>{dayjs(event.event.issueDate).format('DD/MM/YYYY')}</Typography>
                        </Box>
                        <Box>
                            <Typography variant={'body1'} sx={{
                                fontSize: '0.7rem',
                                color: '#8f8f8f',
                                fontWeight: '600',
                                marginBottom: '-2px'
                            }}>Invoice No</Typography>
                            <Typography variant={'body2'} sx={{
                                fontSize: '1.1rem',
                                fontWeight: '700',
                                letterSpacing: '1px'
                            }}>{event.event.invoiceReference}</Typography>
                        </Box>

                    </Box>
                    <Box>
                        <Box sx={{marginBottom: '10px'}}>
                            <Typography variant={'body2'} sx={{
                                fontSize: '0.7rem',
                                color: '#8f8f8f',
                                fontWeight: '600',
                                marginBottom: '-2px'
                            }}>Date due</Typography>
                            <Typography variant={'body2'} sx={{
                                fontSize: '1.1rem',
                                fontWeight: '700',
                                letterSpacing: '1px'
                            }}>{dayjs(event.event.dueDate).format('DD/MM/YYYY')}</Typography>
                        </Box>


                    </Box>
                </Stack>

            </Box>
            <Divider/>
            <Box sx={{
                '& .content p': {
                    fontSize: '1rem',
                    lineHeight: '1.5rem'
                },
                margin: '20px 0'
            }}>
            </Box>

            <Typography variant={'h4'}>Items:</Typography>
            <Box>

                {event.event.items.map((item, index) => {
                    return (
                        <Box key={index}>
                            <Stack key={index} direction={'row'} justifyContent={'space-between'} spacing={1}
                                   sx={{padding: '10px'}}>
                                <Box>
                                    {language.toUpperCase() === 'PL' && (<Typography sx={{fontSize: '1.0rem'}} key={index}
                                                                       variant={'body2'}>{item.namePL}</Typography>)}
                                    {language.toUpperCase() === 'EN' && (<Typography sx={{fontSize: '1.0rem'}} key={index}
                                                                       variant={'body2'}>{item.nameEN}</Typography>)}
                                    {item.extra &&
                                        <Typography variant={'body2'} sx={{fontSize: '0.8rem', color: '#6a6a6a'}}>{item.extra} </Typography>}
                                </Box>
                                <Typography variant={'body2'}
                                            sx={{fontSize: '1.0rem'}}>{item.subTotal}{event.event.currency}</Typography>
                            </Stack>
                            <Divider/>
                        </Box>
                    )
                })}
                <Stack direction={'row'} justifyContent={'space-between'} spacing={1} sx={{padding: '10px'}}>
                    <Typography variant={'body2'} sx={{fontSize: '1.0rem', fontWeight: '600'}}>Total</Typography>
                    <Typography variant={'body2'}
                                sx={{fontSize: '1.0rem'}}>{event.event.amount}{event.event.currency}</Typography>
                </Stack>
            </Box>

        </Box>
    )
}
