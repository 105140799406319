import {useDispatch} from "react-redux";
import {useEffect} from "react";
import {AppDispatch} from "../../store";
import {useAppSelector} from "../index";
import {fetchStudentChangeRequests, selectStudentChangeRequests} from "../../store/feature/user";

export const useFetchStudentChangeRequests = () => {
    const dispatch = useDispatch<AppDispatch>();
    const {activeStudentId} = useAppSelector((state) => state.configuration);

    useEffect(() => {
        if (activeStudentId !== -1) {
            dispatch(fetchStudentChangeRequests({studentId: activeStudentId}));
        }

    }, [dispatch, activeStudentId]);

    return useAppSelector((state) => selectStudentChangeRequests(state));
}
