import {Box} from '@mui/material';
import * as React from 'react';
import {CSSProperties, useEffect, useState} from 'react';
import {SxProps} from "@mui/system";


type Props = {
  src?: string,
  height?: number | string,
  width?: number | string,
  imgStyle?: CSSProperties,
  onClick?: () => void,
  sx?: SxProps
  wrapper?: boolean
}

type PropsImg =  {
  imageUrl?: string,
  onClick?: () => void,
  imgStyle?: CSSProperties,
}


const Image = ({imageUrl, onClick, imgStyle}: PropsImg) => {
  return <>
    {!imageUrl && <img alt={'Loading...'} src={'/loader.gif'} style={{ width: '25%', opacity: 0.3}} />}

    {imageUrl  && (
          <img src={imageUrl} loading="lazy" onClick={onClick}
               style={{width: '100%', height: '100%', objectFit: 'cover', ...imgStyle}}/>
    )}
  </>
}

export const ImagePreview = ({src, height, width, imgStyle, sx, onClick, wrapper = true}: Props) => {

  return (
      <>
      { wrapper && (<Box sx={{
        position: 'relative',
        display: 'inline-block',
        textAlign: 'center',
        alignContent: 'center',
        width: width,
        height: height,
        ...sx
      }}>

        <Image imageUrl={src} onClick={onClick} imgStyle={imgStyle}/>
      </Box>)
      }
        { !wrapper  && <Image imageUrl={src} onClick={onClick} imgStyle={imgStyle}/> }
      </>
  )
}
