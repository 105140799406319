import {Box} from '@mui/material';
import {Controller, UseFormReturn} from 'react-hook-form';
import * as React from 'react';
import {useEffect, useState} from 'react';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, {Dayjs} from 'dayjs';

import {LocalizationProvider, PickersDay, StaticDatePicker} from '@mui/x-date-pickers';
import {styled} from '@mui/material/styles';
import {isWeekend} from "../../utils/misc";
import {PickersDayProps} from "@mui/x-date-pickers/PickersDay/PickersDay";
import {StaticDatePickerProps} from "@mui/x-date-pickers/StaticDatePicker/StaticDatePicker.types";
import {EVENT_TYPES} from "../../themes/eventTypes";
import {useAppSelector} from "../../hooks";

type StaticDatePickerInputFieldProps = {
  name: string
  infoDays?: string[];
  form: UseFormReturn;
} & StaticDatePickerProps<Dayjs> ;

export type DayEvent = {
  date: string;
  name: string;
  fullDay: boolean;
  timeSlot?: string;
};

const DisabledDay = styled(PickersDay<Dayjs>)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: EVENT_TYPES['holiday'].lightColor,
    color: theme.palette.primary.contrastText
  }
}));

const HighlightedDay = styled(PickersDay<Dayjs>)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  }
}));

const EMPTY = () => {
  return (
     <></>
  );
};


const daySelect =  (date: string, highlightedDays: string[], setHighlightedDays: React.Dispatch<React.SetStateAction<string[]>>) => {
  const resultArray = [...highlightedDays];
  const elIndex = resultArray.indexOf(date);
  if (elIndex >= 0) {
    resultArray.splice(elIndex, 1);
  } else {
    resultArray.push(date);
  }
  setHighlightedDays(resultArray);
}

type CalendarDayProps = PickersDayProps<Dayjs> & {
  highlightedDays: string[],
  infoDays: string[],
  setHighlightedDays: React.Dispatch<React.SetStateAction<string[]>>,
}

const ServerDay = ({highlightedDays = [], setHighlightedDays, day, outsideCurrentMonth, infoDays, ...other}: CalendarDayProps) => {
  const dayStr = day.format('YYYY-MM-DD');

  if (infoDays.includes(dayStr)) {
    return <DisabledDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} selected={true} disabled={true}/>;
  }
  const isSelected = !outsideCurrentMonth && (highlightedDays.includes(dayStr));
  return <HighlightedDay {...other}  onDaySelect={() => daySelect(dayStr, highlightedDays, setHighlightedDays)} outsideCurrentMonth={outsideCurrentMonth} day={day} selected={isSelected} />;
};


const StyledStaticDatePicker = styled(StaticDatePicker<Dayjs>)(() => ({
  '& .MuiPickersDay-dayWithMargin': {
    fontSize: '1.5em',
    padding: '20px',
    margin: '3px 4px'
  },
  '& .MuiPickersCalendarHeader-label': {
    textTransform: 'capitalize'
  },
  '& .MuiDayCalendar-weekDayLabel': {
    fontSize: '1.5em',
    padding: '20px',
    margin: '3px 4px'
  },
  '& .MuiDayCalendar-slideTransition': {
    minHeight: '300px'
  },
  '& .MuiDateCalendar-root': {
    width: 'auto',
    height: 'auto',
    maxHeight: 'none'
  }
}));


export const StaticDatePickerInputField = ({
  name,
  shouldDisableDate,
  form,
  infoDays = [],
  ...others
}: StaticDatePickerInputFieldProps) => {
  const [highlightedDays, setHighlightedDays] = useState([]);
  const {language} = useAppSelector((state) => state.configuration);
  useEffect(() => {
    form.setValue(name, highlightedDays);
  }, [name, form, highlightedDays]);

  // @ts-ignore
  // @ts-ignore
  return (
    <Box sx={{ padding: 0 }}>
      <Controller
        name={name}
        control={form.control}
        render={({ fieldState: { error } }) => (
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={language}>
            <StyledStaticDatePicker
              {...others}
              orientation="landscape"
              disableHighlightToday={false}
              shouldDisableDate={shouldDisableDate ? shouldDisableDate : isWeekend}
              timezone={'UTC'}
              minDate={dayjs()}
              slots={{
                // @ts-ignore
                day: ServerDay,
                toolbar: EMPTY,
                actionBar: EMPTY
              }}
              slotProps={{
                day: {
                  // @ts-ignore
                  highlightedDays,
                  setHighlightedDays,
                  infoDays
                }
              }}
            />
          </LocalizationProvider>



        )}
      />
    </Box>
  );
};
