import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack} from "@mui/material";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {LoadingButton} from "@mui/lab";
import {useAppDispatch, useAppSelector} from "../../../hooks";
import {removeStudentAbsence} from "../../../store/feature/user";
import {fetchCalendarEvents} from "../../../store/feature/calendar";
import {getYearMonth} from "../../../utils/misc";
import dayjs, {Dayjs} from "dayjs";

type Props = {
    date: string,
    selectedDate: Dayjs | string
}
export const AbsenceCancellationButton = ({date, selectedDate}: Props) => {

    const {activeStudentId, language} = useAppSelector((state) => state.configuration);

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const handleClose = () => {
        setOpen(false);
    };

    const handleClick = () => {
        setOpen(true);
    };

    const handleAbsenceCancellation = (date: string) => {
        setLoading(true);
        handleClose();
        dispatch(removeStudentAbsence({date, studentId: activeStudentId})).then(() => {
            dispatch(fetchCalendarEvents({studentId: activeStudentId, language, yearMonth: getYearMonth(selectedDate)})).finally(() => {
                setLoading(false);
            })
        }).catch(() => {
            setLoading(false);
        })
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('event.absence.cancel.title')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('event.absence.cancel.text')} <b>{dayjs(date).format('D MMMM')}</b>?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{t('No')}</Button>
                    <Button onClick={() => handleAbsenceCancellation(date)} autoFocus>
                        {t('Yes')}
                    </Button>
                </DialogActions>
            </Dialog>
            <Stack alignItems={'end'}>
                <LoadingButton loading={loading} variant={'outlined'} onClick={handleClick} sx={{padding: '0 10px', borderRadius:'23px'}}>{t('Cancel')}</LoadingButton>
            </Stack>
        </>
    )
}
