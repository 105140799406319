import * as React from 'react';
import {useEffect} from 'react';
import {useLocation} from "react-router-dom";
import {MainRoutes} from "../../routes/MainRoutes";
import {Typography} from "@mui/material";
import {useTranslation} from "react-i18next";


export const PageTitle = () => {

    const [title, setTitle] = React.useState('');
    const {t} = useTranslation();

    const location = useLocation();
    useEffect(() => {

        if (MainRoutes) {
            const page = MainRoutes.children?.filter((p) => p.path === location.pathname);
            if (page && page.length > 0 && page[0].label) {
                setTitle(page[0].label);
            } else {
                setTitle('')
            }
        }
    }, [location.pathname]);

    return (
        <Typography variant="h1" sx={{fontSize:'1rem', display: { xs: 'block', sm: 'none' }}}>{t(`menu.${title}`)}</Typography>
    );
}
