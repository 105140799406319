import * as React from 'react';
import { Dayjs } from 'dayjs';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';

import { PickersCalendarHeaderProps } from '@mui/x-date-pickers/PickersCalendarHeader';

const CustomCalendarHeaderRoot = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    padding: '8px 16px',
    alignItems: 'center',
});

export function CustomCalendarHeader(props: PickersCalendarHeaderProps<Dayjs>) {
    const { currentMonth, onMonthChange } = props;

    const selectNextMonth = () => onMonthChange(currentMonth.add(1, 'month'), 'left');
    const selectPreviousMonth = () =>
        onMonthChange(currentMonth.subtract(1, 'month'), 'right');

    return (
        <CustomCalendarHeaderRoot>
            <Stack spacing={1} direction="row">
                <IconButton onClick={selectPreviousMonth} title="Previous month">
                    <ChevronLeft />
                </IconButton>
            </Stack>
            <Typography variant="body2">{currentMonth.format('MMMM YYYY')}</Typography>
            <Stack spacing={1} direction="row">
                <IconButton onClick={selectNextMonth} title="Next month">
                    <ChevronRight />
                </IconButton>
            </Stack>
        </CustomCalendarHeaderRoot>
    );
}
