import {Box, Button} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import React from "react";
import {useTranslation} from "react-i18next";
import {MainMenu} from "./MainMenu";
import {AbsenceForm} from "./AbsenceForm";
import {CancelMealForm} from "./CacnelMealForm";
import {SuccessView} from "../../SuccessView";
import {CalendarEvent, fetchCalendarEvents} from "../../../../store/feature/calendar";
import {useAppDispatch, useAppSelector} from "../../../../hooks";
import {getYearMonth} from "../../../../utils/misc";
import {Dayjs} from "dayjs";
import {DialogPage} from "../../DialogPage";

type Props = {
    calendarEvents: CalendarEvent[]
    selectedDate: Dayjs | string
    setSelectedDate: (day: Dayjs | string) => void
}
export const PlanChanges = ({calendarEvents, setSelectedDate, selectedDate}: Props) => {
    const {t} = useTranslation();
    const [open, setOpen] = React.useState(false);
    const {activeStudentId, language} = useAppSelector((state) => state.configuration);
    const [dialogView, setDialogView] = React.useState<'main' | 'success' | 'absence' | 'meals'>('main');
    const dispatch = useAppDispatch();
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setDialogView('main');
        updateEvents();
    };

    const updateEvents = () => {
        dispatch(fetchCalendarEvents({studentId:activeStudentId, language, yearMonth:getYearMonth(selectedDate)}))
    }

    const back = () => {
        switch (dialogView) {
            case 'absence':
            case 'meals':
            case 'success': return () => setDialogView('main');
            default:
                return handleClose;
        }
    }

    return (
        <Box justifyContent={"center"} alignContent={"center"} sx={{width: '100%', textAlign: 'center', marginTop: '5px'}}>
            <DialogPage open={open} handleClose={back()}  title={t(`widget.planChanges.${dialogView}.title`)}>
                { dialogView === 'main' && <MainMenu studentId={activeStudentId} goMeal={() => setDialogView('meals')} goAbsence={() => setDialogView('absence')}/> }
                { dialogView === 'success' && <SuccessView close={handleClose}/> }
                { dialogView === 'absence' && <AbsenceForm  selectedDate={selectedDate} setSelectedDate={setSelectedDate} calendarEvents={calendarEvents} studentId={activeStudentId} language={language} goToMainMenu={() => setDialogView('main')} goToSuccess={() => {setDialogView('success'); }}/> }
                { dialogView === 'meals' && <CancelMealForm selectedDate={selectedDate} setSelectedDate={setSelectedDate} calendarEvents={calendarEvents} studentId={activeStudentId} language={language} goToMainMenu={() => setDialogView('main')} goToSuccess={() => {setDialogView('success'); }}/> }
            </DialogPage>

            <Button
                component="label"
                sx={{width: '90%', marginTop: '0px', color: 'white', fontWeight: '500', fontSize: '1rem'}}
                onClick={handleClickOpen}
                style={{borderRadius: 50}}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon/>}
            >
                {t('widget.planChanges.button.title')}
            </Button>
        </Box>)
}
