import dayjs, {Dayjs} from 'dayjs';


export const dayJsDay = (day: string | Dayjs): Dayjs => {
  if (typeof day === 'string') {
    return dayjs.utc(day);
  }
  return day;
};

export const getYesterday = (): Dayjs => {
  return dayjs().add(-1, "day");
}

export const getMinDate = (limitHour: number, limitMinute: number): Dayjs => {
  const now = dayjs();
  const limit = dayjs().hour(limitHour).minute(limitMinute).second(0).millisecond(0);
  return now.isAfter(limit) ? dayjs.utc().add(2, 'day') : dayjs.utc().add(1, 'day');
}

export const getYearMonth = (date: Dayjs | string) => {
  if (typeof date === 'string') {
    return date;
  }

  console.log(date, `${date.year()}-${date.month() + 1}`);


  return `${date.year()}-${date.month() + 1}`
}

export const getYearMonthDayJsDate = (date: Dayjs | string): Dayjs => {
  return dayJsDay(date);
}

export const isWeekend = (date: Dayjs) => {
  return date.day() === 0 || date.day() === 6;
};

export const getDate = (day: string | null): Dayjs | undefined => {
  try {
    return dayjs(day);
  } catch (e) {
    return undefined
  }
}

export const getDateOrToday = (day: string | null): Dayjs => {
  if (!day) {
    return dayjs().utc();
  }

  try {
    return dayjs(day);
  } catch (e) {
    return dayjs().utc()
  }
}

export const isStandaloneMobileApp = () => {
  // @ts-ignore
  return window.navigator.standalone === true || window.matchMedia('(display-mode: standalone)').matches
}

export const getMobileOperatingSystem = () => {
  const userAgent = navigator?.userAgent ?? '';

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return "iOS";
  }

  return "Unknown";
}

