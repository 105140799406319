import React, { useEffect, useState } from 'react';
import {
  /* eslint-disable */
  FieldValues,
  FormProvider,
  Path,
  SubmitErrorHandler,
  SubmitHandler,
  UseFormReturn
} from 'react-hook-form';
import {RejectedRequestError } from '../../types';
import { AsyncThunkAction } from '@reduxjs/toolkit';
import {useAppDispatch} from "../../hooks";
import {AsyncThunkConfig} from "../../generic";


export type FormProps<TFormValues extends FieldValues> = {
  form: UseFormReturn<TFormValues>;
  onError?: SubmitErrorHandler<TFormValues>;
  children: React.ReactNode;
  onBeforeSubmit?: (values: TFormValues) => Promise<{success: boolean; }> | boolean;
  submitAction?: (values: TFormValues) => AsyncThunkAction<TFormValues, TFormValues, AsyncThunkConfig>;
  onSubmitSuccess?: (values: TFormValues) => any;
  id: string;
};

export const Form = <TFormValues extends FieldValues = FieldValues>(props: FormProps<TFormValues>) => {
  const { form, onBeforeSubmit, submitAction, onSubmitSuccess, onError, children, id } = props;
  const [isCustomSubmitSuccessful, setIsCustomSubmitSuccessful] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (onSubmitSuccess && isCustomSubmitSuccessful) {
      const formValues = form.getValues();

      /*
       * Trigger callback on success
       */
      onSubmitSuccess(formValues);

      setIsCustomSubmitSuccessful(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomSubmitSuccessful]);

  const submitDispatcher: SubmitHandler<TFormValues> = (values) => {
    if (!submitAction) return;

    const actionResult = submitAction(values);

    if (!actionResult) {
      setIsCustomSubmitSuccessful(true);
      form.reset();
      return;
    }

    // @ts-ignore
    return dispatch(actionResult).then((response) => {
      const {
        payload,
        meta: { requestStatus }
      } = response;

      if (requestStatus === 'fulfilled') {
        form.reset();
        setIsCustomSubmitSuccessful(true);
        return response;
      }

      console.log(response);

      const {
        response: { errors, message, error }
      } = payload as RejectedRequestError;

      /*
       * Set form errors
       */
      if (errors) {
        Object.keys(errors).forEach((fieldName) => {
          const name = fieldName as Path<TFormValues>;

          form.setError(
            name,
            {
              message: errors[name]
            },
            {
              shouldFocus: true
            }
          );
        });
      } else if (error) {
        form.setError('root.serverError', {
          message: error
        });
      }

      return response;
    });
  };

  const submitHandler: SubmitHandler<TFormValues> = (values) => {
    if (onBeforeSubmit) {
      const onBeforeSubmitResult = onBeforeSubmit(values);
      if ((typeof onBeforeSubmitResult === 'boolean' && onBeforeSubmitResult)) {
        return submitDispatcher(values);
      }
      if (onBeforeSubmitResult instanceof Promise) {
        return onBeforeSubmitResult.then().then(() => submitDispatcher(values))
      }
    }

    return submitDispatcher(values);
  };

  const submitErrorHandler: SubmitErrorHandler<TFormValues> = (errors) => {
    if (onError) {
      onError(errors);
    }
  };

  return (
    <FormProvider {...form}>
      <form noValidate autoComplete="off" onSubmit={form.handleSubmit(submitHandler, submitErrorHandler)} id={id}>
        {children}
      </form>
    </FormProvider>
  );
};
